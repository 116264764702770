import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GiftItem } from 'src/app/Models/gift-item.model';
import { GiftPackage } from 'src/app/Models/gift-package.model';
import { ImageFile } from 'src/app/Models/image-file.model';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailQuickViewService {

  productItem!: GiftItem;
  productPackage!: GiftPackage;
  isCustomizedGift!: boolean;
  isItem!: boolean;
  isOutStock!: boolean;
  reducedPrice!: number;

  public productItemSubject = new Subject<GiftItem>();
  public productPackageSubject = new Subject<GiftPackage>();

  constructor() { }

  changeItem() {
    this.productItemSubject.next(this.productItem);
  }

  changePackage() {
    this.productPackageSubject.next(this.productPackage);
  }

}
