import {Injectable} from '@angular/core';
import {Observable, publishReplay, refCount} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export abstract class BaseCacheableService<T> {
  cache$: Observable<T> | null = null;

  abstract fetchData(): Observable<T>;

  // Get configs from server | HTTP GET
  loadValue(): Observable<T> {
    // Cache it once if cache value is false
    if (!this.cache$) {
      this.cache$ = this.fetchData().pipe(
        publishReplay(1), // this tells Rx to cache the latest emitted
        refCount() // and this tells Rx to keep the Observable alive as long as there are any Subscribers
      );
    }

    return this.cache$;
  }

  // Clear cache
  clearCache() {
    this.cache$ = null;
  }
}
