<!-- nav -->
<header id="xgy-app-header" class="xgy-app-header p-3 position-relative">
  <nav
    id="xgy-app-nav"
    class="xgy-app-nav navbar navbar-expand-lg rounded-pill top-0 z-index-fixed shadow secondary-font position-absolute my-3 py-0 start-0 end-0 mx-4 bg-dark z-3 border border-2 border-dark align-items-center"
  >
    <div class="container-fluid px-0">
      <a
        id="xgy-nav-brand-logo"
        class="xgy-nav-brand-logo navbar-brand ms-2 d-block d-lg-none shadow-none shadow-lg-sm flex-fill"
        [routerLink]="['']"
      >
        <img
          id="xgy-nav-brand-logo-img"
          [src]="logoDark"
          class="xgy-nav-brand-logo-img rounded-pill"
          alt="App Logo"
        />
      </a>
      <ul
        id="xgy-nav-item-wrapper-3-mobile"
        class="xgy-nav-item-wrapper-3 navbar-nav navbar-nav-hover ms-auto me-0 d-flex flex-row justify-content-between d-lg-none justify-content-evenly align-items-center"
      >
        <li id="xgy-nav-item-3-0" class="xgy-nav-item-3-0 nav-item">
          <a class="text-light rounded-pill" (click)="onSearchBar()">
            <i-bs name="search" class="xgy-search-bar bi-1x"></i-bs>
          </a>
        </li>
        <li
          id="xgy-nav-item-3-2"
          class="xgy-nav-item-3-2 nav-item position-relative mx-3"
        >
          <a
            id="xgy-nav-mini-cart-button"
            class="xgy-nav-mini-cart-button text-light rounded-pill primary-font"
            [routerLink]="['cart']"
          >
            @if (currentItemCount > 0) {
<span
             
              id="xgy-nav-mini-cart-badge"
              class="xgy-nav-mini-cart-badge position-absolute translate-middle badge rounded-pill bg-danger fw-normal"
            >
              {{ currentItemCount }}
              <span class="visually-hidden">Cart items</span>
            </span>
}
            <i-bs name="basket" class="xgy-search-bar bi-1x"></i-bs>
          </a>
        </li>
        <li id="xgy-nav-item-3-1" class="xgy-nav-item-3-1 nav-item">
          <app-user-menus-popover></app-user-menus-popover>
        </li>
      </ul>
      <button
        id="xgy-app-nav-toggle"
        class="xgy-app-nav-toggle navbar-toggler shadow-none ms-auto border-0 pb-2 text-light"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#xgy-navigation"
        aria-controls="xgy-navigation"
        aria-expanded="false"
        aria-label="Toggle xgy-navigation"
      >
        <span class="navbar-toggler-icon mt-2">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div
        class="xgy-navigation collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0"
        id="xgy-navigation"
      >
        <!-- <ul id="xgy-nav-item-wrapper-0" class="xgy-nav-item-wrapper-0 navbar-nav navbar-nav-hover ms-3">
                    <li id="xgy-nav-item-0-0" class="xgy-nav-item-0-0 nav-item me-3">
                        <button id="xgy-nav-build-gift-button" class="xgy-nav-build-gift-button nav-link text-light rounded-pill btn btn-secondary px-4 text-light"
                            [routerLink]="['build-your-gift']"><span>Build your own gift</span></button>
                    </li>
                </ul> -->
        <a
          id="xgy-nav-brand-logo"
          class="xgy-nav-brand-logo navbar-brand ms-2 d-none d-lg-block"
          [routerLink]="['']"
        >
          <img
            id="xgy-nav-brand-logo-img"
            [src]="logoDark"
            class="xgy-nav-brand-logo-img rounded-pill"
            alt="App Logo"
          />
        </a>
        <a id="hotline-wrapper" class="hotline-wrapper ms-2 shadow-none shadow-lg-sm btn btn-lg btn-warning rounded-pill d-none d-lg-block border-light border-3" href="tel:{{ supportPhoneFormatted }}">Hotline: {{ supportPhone }}</a>
        <ul
          id="xgy-nav-item-wrapper-2"
          class="xgy-nav-item-wrapper-2 navbar-nav navbar-nav-hover ms-3 ms-lg-auto me-3 me-lg-2 align-items-center"
        >
          <li id="xgy-nav-item-2-0" class="xgy-nav-item-2-0 nav-item me-4">
            <a
              class="nav-link text-light"
              aria-current="page"
              (click)="openOrderStatusDialog()"
              ><span>Order Status</span></a
            >
          </li>
          <li id="xgy-nav-item-2-0" class="xgy-nav-item-2-0 nav-item me-4">
            <a
              class="nav-link text-light"
              aria-current="page"
              [routerLink]="['about']"
              ><span>Our Story</span></a
            >
          </li>
          <!-- <li id="xgy-nav-item-2-1" class="xgy-nav-item-2-1 nav-item">
            <a class="nav-link text-light" aria-current="page" [routerLink]="['suplier-panel']"><span>Supplier panel</span></a>
          </li> -->
        </ul>
        <ul
          id="xgy-nav-item-wrapper-3"
          class="xgy-nav-item-wrapper-3 navbar-nav navbar-nav-hover me-4 d-lg-flex d-none justify-content-evenly align-items-center"
        >
          <li id="xgy-nav-item-3-0" class="xgy-nav-item-3-0 nav-item">
            <a class="text-light rounded-pill" (click)="onSearchBar()">
              <i-bs name="search" class="xgy-search-bar bi-1x"></i-bs>
            </a>
          </li>
          <li
            id="xgy-nav-item-3-2"
            class="xgy-nav-item-3-2 nav-item position-relative mx-4"
          >
            <a
              id="xgy-nav-mini-cart-button"
              class="xgy-nav-mini-cart-button text-light rounded-pill primary-font"
              [routerLink]="['cart']"
            >
              @if (currentItemCount > 0) {
<span
               
                id="xgy-nav-mini-cart-badge"
                class="xgy-nav-mini-cart-badge position-absolute translate-middle badge rounded-pill bg-danger fw-normal"
              >
                {{ currentItemCount }}
                <span class="visually-hidden">Cart items</span>
              </span>
}
              <i-bs name="basket" class="xgy-search-bar bi-1x"></i-bs>
            </a>
          </li>
          <li id="xgy-nav-item-3-1" class="xgy-nav-item-3-1 nav-item">
            <app-user-menus-popover></app-user-menus-popover>
          </li>
        </ul>
      </div>
    </div>
    @if (overlaySearchBar) {
<div
     
      class="overlay-search-bar position-absolute border-0 bg-primary text-light p-0 p-lg-2 h-100 rounded-pill z-3 w-100"
    >
      <div class="d-flex align-items-center">
        <div class="form-floating flex-fill">
          <div class="ng-autocomplete">
            <ng-autocomplete
              #ngSearch
              class="form-control rounded-pill border-0"
              placeholder="Search for anything..."
              [data]="searchSuggestions"
              [searchKeyword]="keyword"
              [isLoading]="isLoading"
              [minQueryLength]="minQueryLength"
              [(ngModel)]="selected"
              [itemTemplate]="itemTemplate"
              [notFoundTemplate]="notFoundTemplate"
              [historyIdentifier]="historyIdentifier"
              [historyHeading]="historyHeading"
              [historyListMaxNumber]="historyListMaxNumber"
              (selected)="selectEvent($event)"
              (inputChanged)="onChangeSearch($event)"
              (inputFocused)="onFocused($event)"
            >
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
              <div class="item">
                <div class="container">
                  <!-- First column with an image -->
                  <div class="search-image-container">
                    <img
                      (error)="onImageError($event)"
                      alt="{{ item.itemName }}"
                      src="{{
                        item.itemCloudFiles
                          | cloudFileByIndexToUrl : 0 : variation
                      }}"
                    />
                    &nbsp;
                  </div>

                  <div class="search-content">
                    <span>{{ item.itemName }}</span
                    ><br />
                    <small>{{ item.itemShortDesc | truncate: [200]}}</small>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
        <button
          class="btn text-light border-0 dismiss-button ms-2 me-2 mb-0"
          (click)="onCloseSearch()"
        >
          <i-bs name="x-lg" class="xgy-search-bar bi-3x"></i-bs>
        </button>
      </div>
    </div>
}
    <a id="hotline-wrapper" class="hotline-wrapper ms-2 shadow-none shadow-lg-sm btn btn-lg btn-warning rounded-pill d-lg-none d-block border-light border-3 position-absolute start-50 translate-middle" href="tel:{{ supportPhoneFormatted }}">Hotline: {{ supportPhone }}</a>
  </nav>
</header>
