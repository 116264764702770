<div class="row primary-font">
  <div class="col">
    <div ngbDropdown class="d-inline-block">
      @if (!(appAuthService.authenticated | async)) {
<a
        id="xgy-nav-loggin-button"
        class="xgy-nav-loggin-button text-dark rounded-pill"
       
        [routerLink]="['/login']"
      >
        <i-bs name="person-circle" class="xgy-loggin bi-1x text-light"></i-bs>
      </a>
}
      @if (appAuthService.authenticated | async) {
<a
        id="xgy-nav-loggin-button"
        class="xgy-nav-loggin-button text-dark rounded-pill"
       
        ngbDropdownToggle
      >
        <button class="btn btn-outline-light rounded-pill p-1 px-2">
          @if (isAuthenticated && picture) {
<img
            alt=""
           
            [src]="picture"
            class="xgy-loggin bi-1x"
            onError="this.src='assets/images/user-profile/no_profile.png'"
          />
}
          @if (isAuthenticated && !picture) {
<i-bs
           
            name="person-circle"
            class="xgy-loggin bi-2x"
          ></i-bs>
}
          <span class="ms-2 small text-truncate d-none d-md-inline">{{ (currentUser | async)?.fullName! | truncate: [25] }}</span>
        </button>
      </a>
}
      @if (appAuthService.authenticated | async) {
<div
        ngbDropdownMenu
        aria-labelledby="xgy-nav-loggin-button"
       
        placement="bottom-end"
        class="border-0 shadow"
      >
        <button
        type="button"
        class="btn btn-primary"
        [routerLink]="['/profile']"
        ngbDropdownItem
      >
      <i-bs
            name="gear-wide-connected"
            class="xgy-profile-settings bi-1x"
          ></i-bs> Profile Settings
      </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="onSignOut()"
          ngbDropdownItem
        >
        <i-bs
            name="box-arrow-left"
            class="xgy-profile-settings bi-1x"
          ></i-bs> Sign Out
        </button>
      </div>
}
    </div>
  </div>
</div>
