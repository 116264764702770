import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customPriceFormat',
})
export class CustomPriceFormatPipe implements PipeTransform {
  transform(
    value: { amount: number | null | undefined; currency: string } | undefined
  ): string {
    if (!value) {
      return '';
    }

    if(value.currency == '' && value.amount == 0) {
      return '';
    }

    const formattedAmount = value.amount?.toLocaleString('en-US', {
      style: 'currency',
      currency: `${value.currency}`,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedAmount ?? '-';
  }
}
