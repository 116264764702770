<section id="track-order-wrapper" class="container-fluid track-order-wrapper rounded-4">
  <div class="d-flex justify-content-end">
    <button type="button" class="btn-close btn-close-white mt-3" aria-label="Close" (click)="activeModal.close()"></button>
  </div>
  <div class="row justify-content-center align-items-center py-3 py-md-5 content">
      <div class="middle-wrapper text-center col-12 col-md-12 col-lg-6 my-2">
          <h4 class="secondary-font fs-2 text-light">Track Your Order</h4>
          <p class="text-light mb-0 w-75 mx-auto">Enter your 7 digit order number. You can find this number in the confirmation email we send after you place the order.</p>
          <div class="d-flex justify-content-center align-items-center mb-0 mt-4">
            <div class="d-flex">
              <div class="">
                <div class="input-container">
                  <input #orderNumberInput type="text" (input)="onChangeInput($event)" [(ngModel)]="orderNumber" [ngModelOptions]="{standalone: true}" id="order-number" placeholder="Order Number">
                </div>
              </div>&nbsp;&nbsp;
              <div class="">
                <button id="track-order" type="button" class="btn btn-lg btn-dark rounded-pill shadow px-4" (click)="tackOrder()">Track</button>
              </div>
            </div>
          </div>
          @if(showError){
            <p class="mt-1" style="font-size: 14px; color: #fff; margin-bottom: -25px;"> {{ errorMessage }} </p>
          }
      </div>
  </div>
</section>
