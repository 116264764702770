import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  FilePickerComponent,
  FilePickerModule,
  FilePickerService,
  FilePreviewModel, FileValidationTypes,
  UploaderCaptions, ValidationError
} from "ngx-awesome-uploader";
import {NgxBootstrapIconsModule} from "ngx-bootstrap-icons";
import {SafeResourceUrl} from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {forkJoin, from, map, mergeMap, Observable, of, toArray} from "rxjs";
import {FilePicker} from "./adapter/file-picker";
import {CloudFileUploaderService} from "./cloud-file-uploader.service";
import { SingleImageUploadResModel, TransformedFile } from './models/single-image-upload-res-model';
import { CloudFile, CloudFileVariation } from './models/cloud-file';

@Component({
  selector: 'xyg-cloud-file-uploader',
  standalone: true,
  imports: [
    FilePickerModule,
    NgxBootstrapIconsModule
  ],
  template: `
    @if (totalMaxSize > 0) {
      <ngx-awesome-uploader
        class="upload-wrapper flex-col flex-md-row mw-100 justify-content-center"
        #uploader
        [fileMaxCount]="10"
        [totalMaxSize]="totalMaxSize"
        [fileMaxSize]="10"
        [uploadType]="uploadType ?? 'multi'"
        [enableCropper]="false"
        [adapter]="adapter"
        [cropperOptions]="cropperOptions"
        (validationError)="onValidationError($event)"
        [fileExtensions]="extensionsArray"
        (uploadSuccess)="onUploadSuccess($event)"
        [enableAutoUpload]="false"
        (removeSuccess)="onRemoveSuccess($event)"
        [itemTemplate]="myItemTemplate"
        (fileAdded)="onFileAdded($event)"
        [customValidator]="customValidator"
        [captions]="captions"
        [accept]="accepts">
        <div class="dropzoneTemplate position-relative">
          <p class="text-center fw-bold mb-0">
            Upload {{forLabel}}
          </p>
          <p class="text-center text-muted">
            <small>Drag & Drop or Click to upload</small>
          </p>
          <p class="text-center mb-0">
            <small>{{ fileCount }} {{forLabel}}</small>
          </p>
        </div>
      </ngx-awesome-uploader>
    } @else {
      <ngx-awesome-uploader
        class="upload-wrapper flex-col flex-md-row mw-100 justify-content-center"
        #uploader
        [fileMaxCount]="10"
        [uploadType]="uploadType ?? 'multi'"
        [enableCropper]="false"
        [adapter]="adapter"
        [cropperOptions]="cropperOptions"
        (validationError)="onValidationError($event)"
        [fileExtensions]="extensionsArray"
        (uploadSuccess)="onUploadSuccess($event)"
        [enableAutoUpload]="false"
        (removeSuccess)="onRemoveSuccess($event)"
        [itemTemplate]="myItemTemplate"
        (fileAdded)="onFileAdded($event)"
        [customValidator]="customValidator"
        [captions]="captions"
        [accept]="accepts">
        <div class="dropzoneTemplate position-relative">
          <p class="text-center fw-bold mb-0">
            Upload {{forLabel}}
          </p>
          <p class="text-center text-muted">
            <small>Drag & Drop or Click to upload</small>
          </p>
          <p class="text-center mb-0">
            <small>{{ fileCount }} {{forLabel}}</small>
          </p>
        </div>
      </ngx-awesome-uploader>
    }

    <br/><span>* Allowed file types are {{accepts}}</span>
    <br/>
    <span>* One file should not be more than 10 MB</span>
    @if(totalMaxSize > 0){
      <span> and all files should not exceed {{totalMaxSize}} MB</span>
    }
    <br/><br/>

    <ng-template
      #myItemTemplate
      let-fileItem="fileItem"
      class="file-item-wrapper"
      let-uploadProgress="uploadProgress">
      <div class="d-flex justify-content-between align-items-center">
        <img [src]="getFileImage(fileItem)" alt="{{fileItem.fileName}}" [width]="50" [height]="50" class="rounded shadow-sm object-fill">
        <p class="small flex-fill mx-2 mb-0">File Size: {{ fileItem.file.size }}<br>
          File Name: <span class="truncate">{{ fileItem.fileName }}</span>
        </p>
        <!-- <p>Upload Progress: {{ uploadProgress }} %</p> -->
        <button class="btn btn-danger py-0 rounded-pill" (click)="onRemoveSuccess(fileItem)">
          <i-bs name="x" class="xgy-loggin"></i-bs>
        </button>
      </div>
    </ng-template>
  `,
  styles: ``
})
export class CloudFileUploaderComponent  implements OnInit, AfterViewInit {

  @Input() uploadType!: string;
  @Input() isUserProfileUploader!: boolean;
  @Input() forLabel!: string;
  @Input() cloudFiles!: CloudFile[];
  @Input() accepts!: string;
  @Input() totalMaxSize: number = 50;
  @Output() public readonly removeCloudFile = new EventEmitter<CloudFile>();

  @ViewChild(FilePickerComponent) uploader!: FilePickerComponent;

  public adapter = new FilePicker(this.http, this.cloudFileUploaderService);
  imageFiles: any[] = [];
  newFiles: FilePreviewModel[] = [];
  fileCount!: number;
  imagesUploaded: boolean = false;
  safeUrl!: SafeResourceUrl;
  extensionsArray: string[] = [];

  public captions: UploaderCaptions = {
    dropzone: {
      title: 'You can drop the files here',
      or: 'or',
      browse: 'Select a file',
    },
    cropper: {
      crop: 'Cut',
      cancel: 'Refusal',
    },
    previewCard: {
      remove: 'Remove',
      uploadError: 'File failed to load',
    },
  };

  public cropperOptions = {
    minContainerWidth: '300',
    minContainerHeight: '300',
  };

  constructor(private http: HttpClient,
              private cloudFileUploaderService: CloudFileUploaderService,
              private fileService: FilePickerService,
              private _snackBar: MatSnackBar) {
  }

  public ngOnInit(): void {
    this.extensionsArray = this.accepts
      .replace(/ /g, '') // Remove spaces
      .split(',')
      .map((extension: string) => extension.replace('.', ''));
  }

  createFilePreview() {
    if(this.cloudFiles){
      from(this.cloudFiles)
        .pipe(
          mergeMap(cloudFile => {
              let blobBaseUrl = cloudFile.cloudProvider?.blobBaseUrl??cloudFile.cloudProvider?.publicBaseUrl;
              return this.http.get(blobBaseUrl + cloudFile.originalUri, {
                observe: 'response',
                responseType: 'blob',
                headers : { 'Authorization-Anonymous': 'true' }
              })
                .pipe(
                  map(response => {
                    return {cloudFile: cloudFile, blob: response.body};
                  })
                );
            }
          ),
          map(wrapper => ({
            file: new File([wrapper.blob as Blob], wrapper.cloudFile.name, {type: wrapper.cloudFile.contentType}),
            fileName: wrapper.cloudFile.name
          }) as FilePreviewModel),
          toArray()
        ).subscribe(models => {
        models.map(model => {
          this.imageFiles.push(model);
          this.fileCount = this.imageFiles.length;
        });
        this.uploader?.setFiles(this.imageFiles);
      });
    }
  }

  ngAfterViewInit(): void {
    this.createFilePreview();
  }

  public resetCloudFiles(): void {
    this.newFiles.forEach(i => {
      this.uploader?.removeFile(i);
    });
    this.imageFiles.forEach(i => {
      this.uploader?.removeFile(i);
    });
    this.newFiles = [];
    this.imageFiles = [];
    this.fileCount = 0;
  }

  public customValidator(file: File): Observable<boolean> {
    return of(true);
  }

  public hasNewCloudFiles(): boolean {
    return this.newFiles.length > 0;
  }
  public getNewCloudFiles(image : boolean = false): Observable<CloudFile[]> {
    return from(this.newFiles).pipe(
      map((file: any | null) => image ? this.adapter.uploadImageFile(file) : this.adapter.uploadFile(file)),//transform file into file upload request obs
      toArray(),//collate the obs into an obs array
      mergeMap((obs) => forkJoin(obs)),//launch each obs request and fork-join( wait for all to complete), merge into current pipe
      map(resArray => resArray.map(e => e.body as SingleImageUploadResModel)),// cast body to upload response
      map(responseArray => this.createCloudFiles(responseArray)),//create cloud file
    )
  }

  private createCloudFiles(uploadResArray: SingleImageUploadResModel[]): CloudFile[] {
    return uploadResArray.map(u => {
        const trans = u.transformedFiles.find((f) => f.isThumbnail);
        return ({
          name: u.filePath.substring(u.filePath.lastIndexOf('/') + 1, u.filePath.lastIndexOf('.')),
          originalUri: u.filePath,
          thumbnailUri: trans ? trans.filePath : "",
          cloudProviderId: u.cloudProvider,
          contentType: u.contentType,
          cloudFileVariations: this.createCloudFileVariations(u.transformedFiles)
        });
      }
    );
  }

  private createCloudFileVariations(transformedFileArray: TransformedFile[]): CloudFileVariation[] {
    return transformedFileArray.map(f => ({
      uri: f.filePath,
      variationName: f.transformationName
    }));
  }

  public onFileAdded(file: FilePreviewModel) {
    let sameFileDetected = false;
    if (file) {
      this.imageFiles.forEach(myFile => {
        if (myFile.fileName === file.fileName) {
          sameFileDetected = true;
        }
      })
      this.newFiles.forEach(myFile => {
        if (myFile.fileName === file.fileName) {
          sameFileDetected = true;
        }
      })
    }

    if (!sameFileDetected) {
      this.newFiles.push(file);
      this.imagesUploaded = true;
    }
    this.fileCount = this.imageFiles.length + this.newFiles.length;
  }

  public getFileImage(file: FilePreviewModel) {
    if (file) {
      if(!file.file.type.includes('image')){
        return '/assets/images/empty-states/file-preview.png';
      }
      this.safeUrl = this.getSafeUrl(file.file);
    }
    return this.safeUrl;
  }

  public getSafeUrl(file: File | Blob): SafeResourceUrl {
    return this.fileService.createSafeUrl(file);
  }


  public onRemoveSuccess(e: FilePreviewModel) {
    let fileIndexOfCloudFiles = -1;
    let fileIndex = this.imageFiles.findIndex(file => file.fileName === e.fileName); //checks for removing file inside imageFiles array
    let fileIndexOfNewImages = this.newFiles.findIndex((file: any) => file.fileName === e.fileName); //checks for removing file inside new array
    if(this.cloudFiles) {
      fileIndexOfCloudFiles = this.cloudFiles.findIndex((file: CloudFile) => file.name === e.fileName); //checks for removing file inside cloudFiles array
    }

    if (fileIndex >= 0) {
      //TODO: collect ids of items to be deleted
      this.imageFiles.splice(fileIndex, 1); //remove the file from imageFiles array
    }

    if (fileIndexOfNewImages >= 0) {
      this.newFiles.splice(fileIndexOfNewImages, 1); //remove the file from newFiles array
    }

    if (fileIndexOfCloudFiles >= 0) {
      this.removeCloudFile.next(this.cloudFiles[fileIndexOfCloudFiles]);
      this.cloudFiles.splice(fileIndexOfCloudFiles, 1); //remove the file from newFiles array
    }

    this.uploader?.removeFile(e); //remove the file from uploader
    this.fileCount -= 1;
  }

  public getNewImageCloudFiles(): Observable<CloudFile[]> {
    return from(this.newFiles).pipe(
      map((file: any | null) => this.adapter.uploadImageFile(file)),//transform file into file upload request obs
      toArray(),//collate the obs into an obs array
      mergeMap((obs) => forkJoin(obs)),//launch each obs request and fork-join( wait for all to complete), merge into current pipe
      map(resArray => resArray.map(e => e.body as SingleImageUploadResModel)),// cast body to upload response
      map(responseArray => this.createImageCloudFiles(responseArray)),//create cloud file
    )
  }

  private createImageCloudFiles(uploadResArray: SingleImageUploadResModel[]): CloudFile[] {
    return uploadResArray.map(u => {
        const trans = u.transformedFiles.find((f) => f.isThumbnail);
        return ({
          name: u.filePath.substring(u.filePath.lastIndexOf('/') + 1, u.filePath.lastIndexOf('.')),
          originalUri: u.filePath,
          thumbnailUri: trans ? trans.filePath : "",
          cloudProviderId: u.cloudProvider,
          contentType: u.contentType,
          cloudFileVariations: this.createCloudFileVariations(u.transformedFiles)
        });
      }
    );
  }

  public onUploadSuccess(e: FilePreviewModel): void {
  }

  public onValidationError(error: ValidationError): void {
    this._snackBar.open(`${this.getErrorMessage(error.error as FileValidationTypes, error.file?.name)}`, 'Close');
  }

  getErrorMessage(type: FileValidationTypes, fileName?: string): string {
    switch (type) {
      case FileValidationTypes.fileMaxSize:
        return `Maximum file size exceeded for ${fileName}`;
      case FileValidationTypes.fileMaxCount:
        return `Maximum file count exceeded`;
      case FileValidationTypes.totalMaxSize:
        return `Total maximum size exceeded`;
      case FileValidationTypes.extensions:
        return `This file type is not allowed "${fileName}"`;
      case FileValidationTypes.uploadType:
        return `Invalid upload type`;
      case FileValidationTypes.customValidator:
        return `Custom validation failed`;
      default:
        return `Unknown validation error`;
    }
  }
}
