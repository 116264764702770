export class SingleImageUploadModel {
  file: File;
  name: string;

  constructor(file: File, name: string) {
    this.file = file;
    this.name = name;
  }
}

export class SingleFileUploadModel {
  file: File;
  name: string;

  constructor(file: File, name: string) {
    this.file = file;
    this.name = name;
  }
}
