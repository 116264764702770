<footer class="footer bg-dark mx-1 mx-md-5 rounded-5 mb-5">
    <div class="row p-5 justify-content-center">
        <div class="col-12 text-center">
            <a id="xgy-footer-brand-logo" class="xgy-footer-brand-logo mx-auto"
                [routerLink]="['']">
                <img id="xgy-footer-brand-logo-img" [src]="logoLight"
                    class="xgy-footer-brand-logo-img rounded-pill text-center" alt="App Logo">
            </a>
        </div>
        <div class="col-12 col-lg-8">
            <div class="row my-3">
                <div class="col-12 mb-3">
                    <p class="xgy-footer-bio mt-3 text-light text-center">
                        At WrapMe, we believe in the magic of gifting. We understand that a thoughtful gift has the power to brighten someone's day, strengthen relationships, and create unforgettable memories. That's why we've curated a unique online gift delivery platform that encapsulates happiness in a box. Our mission is to revolutionize the process of gifting, fostering joy for both those who receive and those who give, resulting in smiles all around.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-8 ps-0 ps-lg-5 text-light text-center">
            <div class="row">
                <div class="col-12 col-lg-4 footer-link-section">
                    <h5 class="footer-link-heading secondary-font">SHOP</h5>
                    <ul class="footer-links-wrapper list-unstyled">
                        <li class="footer-link-items" (click)="toCustomGifts()">
                            <span>Build your own gift</span>
                        </li>
                        <li class="footer-link-items" (click)="toStore()">
                            <span>Buy from the store</span>
                        </li>
                        <!-- <li class="footer-link-items">
                            <span>Individual gifts</span>
                        </li>
                        <li class="footer-link-items">
                            <span>Gift cards</span>
                        </li> -->
                    </ul>
                </div>
                <div class="col-12 col-lg-4 footer-link-section text-center">
                    <h5 class="footer-link-heading secondary-font">CORPORATE</h5>
                    <ul class="footer-links-wrapper list-unstyled">
                        <li class="footer-link-items" [routerLink]="['corporate']">
                            <span>Corporate gifts</span>
                        </li>
                        <li class="footer-link-items" [routerLink]="['terms']">
                            <span>Terms of service</span>
                        </li>
                        <li class="footer-link-items" [routerLink]="['privacy-policy']">
                            <span>Privacy policy</span>
                        </li>
                        <li class="footer-link-items" [routerLink]="['data-policy']">
                            <span>Data deletion policy</span>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-lg-4 footer-link-section text-center">
                    <h5 class="footer-link-heading secondary-font">ACCOUNT</h5>
                    <ul class="footer-links-wrapper list-unstyled">
                        <li class="footer-link-items" [routerLink]="['login']">
                            <span>Log in</span>
                        </li>
                        <li class="footer-link-items" [routerLink]="['login']">
                            <span>Create an account</span>
                        </li>
                        <li class="footer-link-items" [routerLink]="['contactus']">
                            <span>Contact us</span>
                        </li>
                    </ul>
                </div>
                <div class="col-12 my-3">
                    <div class="xgy-social-links-wrapper d-flex text-light justify-content-center">
                        <div class="border border-1 border-light rounded-circle me-3 px-2 py-2 social-item text-center">
                            <a class="text-light" href="//www.facebook.com/profile.php?id=61550088912756" target="_blank"><i class="fa-brands fa-facebook-f xgy-social-facebook"></i></a>
                        </div>
                        <div class="border border-1 border-light rounded-circle me-3 px-2 py-2 social-item text-center">
                          <a class="text-light" href="//www.instagram.com/wrapmelk" target="_blank"><i class="fa-brands fa-instagram xgy-social-insta"></i></a>
                        </div>
                        <div class="border border-1 border-light rounded-circle me-3 px-2 py-2 social-item text-center">
                            <a class="text-light" href="//www.tiktok.com/@wrapme.lk" target="_blank"><i class="fa-brands fa-tiktok xgy-social-tiktok"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-3 bg-secondary mx-0 rounded-5 rounded-top text-center">
        <div class="col-12">
            <p class="text-center my-0">WrapMe.lk | Copyrights &copy; {{ currentYear }}</p>
        </div>
    </div>
</footer>
