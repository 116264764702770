<!-- <div class="position-relative">
  <div class="lds-ring mx-auto text-center position-absolute top-50 start-50 translate-middle" *ngIf="showSpinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div> -->

@if (showSpinner) {
<div class='Cube panelLoad secondary-font'>
  <div class='cube-face cube-face-front'>W</div>
  <div class='cube-face cube-face-left'>r</div>
  <div class='cube-face cube-face-bottom'>a</div>
  <div class='cube-face cube-face-right'>p</div>
  <div class='cube-face cube-face-back'>M</div>
  <div class='cube-face cube-face-top'>e</div>
</div>
}
