export interface PublishEvent {
  notificationEvent: NotificationEvent;
  data: any;
}

export enum NotificationEvent {
  NewOrder = 'NewOrder'
}

export enum NotificationGroup {
  Administrator = 'Administrator'
}

export enum InvokeMethod {
  SendNotificationAsync = 'SendNotificationAsync',
  SendNotificationToUserAsync = 'SendNotificationToUserAsync',
  SendNotificationToGroupAsync = 'SendNotificationToGroupAsync',
  AddToGroupAsync = 'AddToGroupAsync'
}
