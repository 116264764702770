import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ThrottleClickDirective} from "../directives/throttle-click.directive";
import {DebounceClickDirective} from "../directives/debounce-click.directive";



@NgModule({
  declarations: [
    ThrottleClickDirective,
    DebounceClickDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ThrottleClickDirective,
    DebounceClickDirective
  ]
})
export class CommonDirectivesModule { }
