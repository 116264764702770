import {ChangeDetectorRef, Component} from '@angular/core';
import {SpinnerService} from "../spinner.service";



@Component({
  selector: 'xyg-loading-spinner',
  standalone: true,
  imports: [],
  templateUrl: './loading-spinner.component.html',
  styleUrl: './loading-spinner.component.scss'
})
export class LoadingSpinnerComponent {
  showSpinner: boolean = false;

  constructor(
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.init();
  }

  init() {
    this.spinnerService.getSpinnerObserver().subscribe((status) => {
      this.showSpinner = status;
      this.cdRef.detectChanges();
    });
  }
}
