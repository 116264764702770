import { ShoppingCartService } from 'src/app/Services/shopping-cart.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppAuthService } from '../app-auth.service';
import { AppUserService } from '../app-user.service';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserInfoModel } from '../../Models/user-info-model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-menus-popover',
  templateUrl: './user-menus-popover.component.html',
  styleUrls: ['./user-menus-popover.component.scss'],
})
export class UserMenusPopoverComponent implements OnInit {
  itemCount!: number;
  public picture!: string;
  isAuthenticated!: boolean;
  currentUser!: Observable<UserInfoModel>;

  constructor(
    config: NgbDropdownConfig,
    private router: Router,
    public appAuthService: AppAuthService,
    public appUserService: AppUserService,
    public cartService: ShoppingCartService,
    private cdr: ChangeDetectorRef
  ) {
    config.placement = 'bottom-end';
    //"top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end";
  }

  ngOnInit(): void {
    this.currentUser = this.appUserService.currentUser;
  }

  onSignOut() {
    this.appAuthService.signOut();
    this.appUserService.clearCurrentUser();
    this.router.navigateByUrl('login');
    this.setPicture();
  }

  ngAfterContentChecked(): void {
    //this.cdr.detectChanges();
    this.setPicture();
  }

  ngAfterViewInit(): void {
    this.setPicture();
  }

  private setPicture(): void {
    this.appAuthService.authenticated.subscribe((authenticated) => {
      if (authenticated) {
        this.isAuthenticated = authenticated;
        this.appUserService.currentUser.subscribe((response) => {
          if (response.picture) {
            this.picture = response.picture;
          }
        });
      } else {
        this.picture = '';
      }
    });
  }
}
