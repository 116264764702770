import { Component, OnInit } from '@angular/core';
import { SitemapService } from './sitemap.service';
import { CommonModule } from '@angular/common';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-sitemap',
  template: `
    <div class="sitemap-container">
      <h2>Sitemap</h2>
      <button (click)="downloadSitemap()">Download XML Sitemap</button>
      <table>
        <thead>
          <tr>
            <th>Location</th>
            <th>Last Modified</th>
            <th>Priority</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let url of allUrls">
            <td><a [href]="url.loc" target="_blank">{{ url.loc }}</a></td>
            <td>{{ url.lastmod | date: 'medium' }}</td>
            <td>{{ url.priority }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  `,
  styles: [
    `
      .sitemap-container {
        padding: 16px;
        max-width: 100%;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 16px;
      }

      th, td {
        border: 1px solid #ddd;
        padding: 8px;
      }

      th {
        background-color: #f2f2f2;
        text-align: left;
      }

      a {
        text-decoration: none;
        color: #3498db;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export class SitemapComponent implements OnInit {
  allUrls: Array<{ loc: string; lastmod: string; priority: number }> = [];
  appBaseUrl = 'https://localhost:44437';

  constructor(private sitemapService: SitemapService) {}

  ngOnInit(): void {
    // Fetch items and packages and prepare URLs
    forkJoin([this.sitemapService.getItems(), this.sitemapService.getPackages()]).subscribe(
      ([items, packages]) => {
        // Generate and combine all URLs
        const staticUrls = this.sitemapService.generateStaticUrls();
        const itemUrls = this.sitemapService.generateItemsUrls(items);
        const packageUrls = this.sitemapService.generatePackagesUrls(packages);

        this.allUrls = [...staticUrls, ...itemUrls, ...packageUrls];
      }
    );
  }

  downloadSitemap(): void {
    this.sitemapService.generateFullSitemap().subscribe((sitemapXml) => {
      const blob = new Blob([sitemapXml], { type: 'application/xml' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'sitemap.xml';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
  
}
