import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from 'src/app/Services/order.service';

@Component({
  standalone: true,
  selector: 'xgy-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.scss'],
  imports: [FormsModule],
})
export class TrackOrderComponent implements AfterViewInit  {
  @ViewChild('orderNumberInput') orderNumberInput!: ElementRef;

  orderNumber!: string;
  errorMessage!: string;
  showError: boolean = false;

  constructor(
    private router: Router,
    private orderService: OrderService,
    public activeModal: NgbActiveModal
  ) {}

  ngAfterViewInit() {
    this.orderNumberInput.nativeElement.focus();
  }

  onChangeInput(event: any) {
    if (this.showError) {
      this.showErrorMessage("", false)
    }
  }

  tackOrder() {
    if (this.orderNumber) {
      this.orderService.loadOrder(this.orderNumber).subscribe({
        next: () => {
          this.activeModal.dismiss();
          this.router.navigate(['/', 'track', `${this.orderNumber}`]);
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.showErrorMessage("Order not found. Please check your order number and try again.");
          } else {
            this.showErrorMessage("An error occurred while retrieving order information, please try again.");
          }
        },
      });
    } else {
      this.showErrorMessage("Enter a valid order number and try again.");
    }
  }

  private showErrorMessage(message: string, show: boolean = true) {
    this.showError = show;
    this.errorMessage = message;
  }
}
