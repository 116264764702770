import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbActiveModal, NgbCarouselModule, NgbDropdownConfig, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {IconsModule} from '../icons/icons.module';
import {GiftStepsNavComponent} from './gift-steps-nav/gift-steps-nav.component';
import {ProductDetailQuickViewComponent} from './product-detail-quick-view/product-detail-quick-view.component';
import {UsernamePasswordLoginComponent} from './username-password-login/username-password-login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
  AddToCartAmountUpdateMessageComponent
} from './add-to-cart-amount-update-message/add-to-cart-amount-update-message.component';
import {CustomizableCartPopupComponent} from './customizable-cart-popup/customizable-cart-popup.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {ErrorModalComponent} from './error-modal/error-modal.component';
import {RouterModule} from "@angular/router";
import {CommonsModule} from "../commons/commons.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatChipsModule} from "@angular/material/chips";
import {CarouselModule} from 'ngx-owl-carousel-o';
import {ProductModule} from "../product/product.module";
import { ViewOrderDetailsComponent } from './user-profile/view-order-details/view-order-details.component';
import {LoadingSpinnerComponent} from "@xyg/spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    GiftStepsNavComponent,
    ProductDetailQuickViewComponent,
    UsernamePasswordLoginComponent,
    AddToCartAmountUpdateMessageComponent,
    CustomizableCartPopupComponent,
    ErrorModalComponent,
    ViewOrderDetailsComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatTooltipModule,
    NgbDropdownModule,
    NgbCarouselModule,
    IconsModule,
    AutocompleteLibModule,
    CommonsModule,
    ProductModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    CarouselModule,
    LoadingSpinnerComponent
  ],
  exports: [
    GiftStepsNavComponent,
    ProductDetailQuickViewComponent,
    CustomizableCartPopupComponent,
  ],
  providers: [
    NgbDropdownConfig,
    NgbActiveModal
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
