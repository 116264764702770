import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconsModule} from '../icons/icons.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {SharedModule} from '../shared/shared.module';
import {CommonsModule} from "../commons/commons.module";
import {OrderTimelineComponent} from "../track/order-timeline/order-timeline.component";

@NgModule({
  declarations: [
    OrderTimelineComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconsModule,
    MatSelectModule,
    SharedModule,
    CommonsModule,
  ],
  exports: [
    OrderTimelineComponent
  ]
})
export class ComponentsModule { }
