import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  autoFillForm!: any;
  constructor() {}

  getItem(key: string): string | null {
    // return localStorage.getItem(key);
    return sessionStorage.getItem(key);
  }

  setItem(key: string, value: string): void {
    // return localStorage.setItem(key, value);
    return sessionStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    // localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }

  setFormData(config?: any) {
    this.autoFillForm = config;
  }

  getForm() {
    return this.autoFillForm;
  }
}
