import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { InvokeMethod, NotificationEvent, PublishEvent } from '../Models/signal-r.enum';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { EventBusService } from '../Services/event-bus.service';
import { BehaviorSubject, Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  soundPath = '../../assets/sounds/notification.mp3';

  private newNotifications: string[] = [];
  public _newNotifications$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private snackBarService: MatSnackBar,
    private eventBusService: EventBusService) {}

  public startConnection(){
    return new Promise((resolve, reject) => {
      this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiBaseUrl}notify`)
      .build();

      this.hubConnection
      .start()
      .then(() => {
        console.info("Connected to Notification Hub");
        return resolve(true);
      }).catch((error: any) => {
        console.error(error);
        reject(error);
      })
    });
  }

  public isConnected(): boolean {
    return (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected);
  }

  public stopConnection(): void{
    if(this.isConnected()){
      this.hubConnection
      .stop()
      .then(() => {
        console.info("Stopped connection to the Notification Hub");
      }).catch((error: any) => {
        console.error(error);
      });
    }
  }

  public listenToNotifications(callback: (message: PublishEvent) => void): void {
    this.hubConnection.on(InvokeMethod.SendNotificationAsync, (message: string) => {
      const event: PublishEvent = JSON.parse(message);
      this.createNotification(event);
      callback(event);
    });
  }

  public sendNotification(message: string): void {
    this.hubConnection.invoke(InvokeMethod.SendNotificationToUserAsync, message);
  }

  public sendNotificationToGroup(group: string, message: string): void {
    this.hubConnection.invoke(InvokeMethod.SendNotificationToGroupAsync, group, message);
  }

  public joinGroupFeed(groupName: string) {
    return new Promise((resolve, reject) => {
      this.hubConnection
      .invoke(InvokeMethod.AddToGroupAsync, groupName)
      .then(() => {
        console.info('Joined group: ', groupName);
        return resolve(true);
      }, (err: any) => {
        return reject(err);
      });
    })
  }

  createNotification(publishEvent: PublishEvent): void {
    this.eventBusService.event.emit(publishEvent);
    switch (publishEvent.notificationEvent) {
      case NotificationEvent.NewOrder:
        const notification = `A new order has been placed! (${publishEvent.data as string})`;
        this.addNotification(notification, true);
        break;

      default:
        break;
    }
  }

  addNotification(message: string, playSound: boolean): void {
    if(!this.newNotifications.includes(message)){
      this.newNotifications.push(message);
      this._newNotifications$.next(this.newNotifications);

      if(playSound){
        let audio = new Audio();
        audio.src = this.soundPath;
        audio.load();
        audio.muted = false;
        audio.play();
      }

      this.snackBarService.open(message, '🗙', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'light-success-snackbar'
      });
    }
  }

  get getNotifications(): Observable<string[]> {
    if(!this._newNotifications$.value){
      return of([]);
    }
    return this._newNotifications$.asObservable().pipe(
      map(messages => messages)
    );
  }
}
