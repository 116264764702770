import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FilePickerAdapter, FilePreviewModel, UploadResponse, UploadStatus} from 'ngx-awesome-uploader';
import { CloudFileUploaderService } from '../cloud-file-uploader.service';
import {SingleFileUploadModel, SingleImageUploadModel } from '../models/single-image-upload-model';



export class FilePicker extends FilePickerAdapter {
  constructor(private http: HttpClient, private cloudFileUploaderService: CloudFileUploaderService) {
    super();
  }

  public uploadImageFile(fileItem: FilePreviewModel): Observable<UploadResponse> {
    var fileName = fileItem.fileName.substring(fileItem.fileName.lastIndexOf('/') + 1, fileItem.fileName.lastIndexOf('.'));
    var imageUploadModel: SingleImageUploadModel = new SingleImageUploadModel(
      fileItem.file as File, fileName);
    return this.cloudFileUploaderService
      .uploadSingleImage(imageUploadModel)
      .pipe(
        map(res => ({body: res, progress: 100, status: UploadStatus.UPLOADED}))
      );
  }

  public uploadFile(fileItem: FilePreviewModel): Observable<UploadResponse> {
    var fileName = fileItem.fileName.substring(fileItem.fileName.lastIndexOf('/') + 1, fileItem.fileName.lastIndexOf('.'));
    var fileUploadModel: SingleFileUploadModel = new SingleFileUploadModel(
      fileItem.file as File, fileName);
    return this.cloudFileUploaderService
      .uploadSingleFile(fileUploadModel)
      .pipe(
        map(res => ({body: res, progress: 100, status: UploadStatus.UPLOADED}))
      );
  }

  public removeFile(fileItem: FilePreviewModel): Observable<any> {
    const id = 50;
    const responseFromBackend = fileItem.uploadResponse;
    const removeApi =
      'https://run.mocky.io/v3/dedf88ec-7ce8-429a-829b-bd2fc55352bc';
    return this.http.post(removeApi, {id});
  }
}
