import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CategoryGroup } from '../Models/category-group.model';
import { CarouselImage } from '../Models/carousel-image.model';
import { GiftPackage } from '../Models/gift-package.model';

@Injectable()
export class HomeService {
  constructor(private http: HttpClient) {}

  async getCategoryGroups(): Promise<CategoryGroup[]> {
    try {
      const data = await this.http.get<CategoryGroup[]>(environment.apiBaseUrl + 'api/Home/category-groups');
      return firstValueFrom(data);
    } catch (error: any) {
      throw new Error('Error fetching category groups: ' + error.message);
    }
  }

  async getCarouselImages(): Promise<CarouselImage[]> {
    try {
      const data = await this.http.get<CarouselImage[]>(environment.apiBaseUrl + 'api/Home/carousel-images');
      return firstValueFrom(data);
    } catch (error: any) {
      throw new Error('Error fetching carousel images: ' + error.message);
    }
  }

  async getOccasionalGifts(): Promise<GiftPackage[]> {
    try {
      const data = await this.http.get<GiftPackage[]>(environment.apiBaseUrl + 'api/Home/occasional-gifts');
      return firstValueFrom(data);
    } catch (error: any) {
      throw new Error('Error fetching occasional gifts: ' + error.message);
    }
  }

  async getViralGifts(): Promise<any[]> {
    try {
      const data = await this.http.get<any[]>(environment.apiBaseUrl + 'api/Order/best-sellers');
      return firstValueFrom(data);
    } catch (error: any) {
      throw new Error('Error fetching viral gifts: ' + error.message);
    }
  }

  async getPlaceholderViralGifts(take: number): Promise<GiftPackage[]> {
    try {
      const data = await this.http.get<GiftPackage[]>(environment.apiBaseUrl + `api/Home/placeholders-viral-gifts?take=${take}`);
      return firstValueFrom(data);
    } catch (error: any) {
      throw new Error('Error fetching viral gifts: ' + error.message);
    }
  }
}
