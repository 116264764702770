import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CloudFileToUrlPipe} from "../pipes/cloud-file-to-url.pipe";
import {CloudFileByIndexToUrlPipe} from "../pipes/cloud-file-by-index-to-url.pipe";
import {CustomPriceFormatPipe} from "../pipes/custom-price-format.pipe";



@NgModule({
  declarations: [
    CloudFileToUrlPipe,
    CloudFileByIndexToUrlPipe,
    CustomPriceFormatPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CloudFileToUrlPipe,
    CloudFileByIndexToUrlPipe,
    CustomPriceFormatPipe
  ]
})
export class CommonPipesModule { }
