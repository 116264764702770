import {Pipe, PipeTransform} from '@angular/core';
import {CloudFile} from "../Models/cloud-file";
import {CloudFileToUrlPipe} from "./cloud-file-to-url.pipe";

@Pipe({
  name: 'cloudFileByIndexToUrl'
})
export class CloudFileByIndexToUrlPipe implements PipeTransform {

  cloudFileToUrlPipe = new CloudFileToUrlPipe();

  transform(array: { cloudFile: CloudFile}[], index: number, variation?: string): string {
    if (index < array.length) {
      return this.cloudFileToUrlPipe.transform(array[index].cloudFile, variation);
    } else if (array.length == 0) {
      return ''
    }
    return this.cloudFileToUrlPipe.transform(array[0].cloudFile, variation);
  }

}
