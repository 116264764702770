import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CloudFileUploaderComponent} from "@xyg/cloud-file-uploader";
import {FilePickerModule} from "ngx-awesome-uploader";
import {IconsModule} from "../icons/icons.module";
import {CustomToastMessageComponent} from "../shared/custom-toast-message/custom-toast-message.component";
import {NgbAlertModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonPipesModule} from "./common-pipes.module";
import {CommonDirectivesModule} from "./common-directives.module";


@NgModule({
  declarations: [
    CustomToastMessageComponent,
  ],
  imports: [
    CommonModule,
    CommonPipesModule,
    CommonDirectivesModule,
    FilePickerModule,
    IconsModule,
    NgbAlertModule,
    CloudFileUploaderComponent
  ],
  exports:[
    CustomToastMessageComponent,
    CommonPipesModule,
    CommonDirectivesModule
  ]
})
export class CommonsModule { }
