import { Pipe, PipeTransform } from '@angular/core';
import {CloudFile, CloudFileVariation} from '../Models/cloud-file';

@Pipe({
  name: 'cloudFileToUrl'
})
export class CloudFileToUrlPipe implements PipeTransform {

  transform(value: CloudFile, variation?: string): string {
    if (value.cloudProvider) {
      switch(variation){
        case 'thumbnail':
          return this.full(value, value.thumbnailUri);
        case 'original':
          return this.full(value, value.originalUri);
        default: {
          if(value.cloudFileVariations && value.cloudFileVariations.length > 0) {
            let fileVariation = value.cloudFileVariations.filter( fileVariation => fileVariation.variationName === variation )
            if(fileVariation.length > 0 ){
              return  this.full(value,fileVariation[0].uri);
            }
          }
          return value.thumbnailUri && value.thumbnailUri.length > 0 ?
            this.full(value, value.thumbnailUri) :
            this.full(value,value.originalUri);
        }
      }
    }
    return '';
  }

  full(value: CloudFile, relativeUrl : string) : string {
    return value.cloudProvider?.publicBaseUrl + relativeUrl;
  }
}
