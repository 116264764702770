import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard, CanActivateProfile, CanActivateReadBackOffice} from './shared/permission.service';
import { CustomRedirectComponent } from './shared/custom-redirect/custom-redirect.component';
import { SitemapComponent } from './sitemap/sitemap.component';

const routes: Routes = [
  { 
    path: 'sitemap.xml', component: SitemapComponent 
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },

  {
    path: 'backoffice',
    loadChildren: () => import('./../../projects/admin-panel/src/public-api').then(m => m.AdminPanelModule),
    data: {
      expectedRole: 'admin'
    },
    canMatch: [AuthGuard,CanActivateReadBackOffice],
  },
  {
    path: 'build-your-gift',
    loadChildren: () => import('./build-your-gift/build-your-gift.module').then(m => m.BuildYourGiftModule),
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then(m => m.CartModule)
  },
  {
    path: 'store/:product',
    loadChildren: () => import('./store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'store/:product/:name',
    loadChildren: () => import('./store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'store',
    loadChildren: () => import('./store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
    canActivate: [AuthGuard, CanActivateProfile],
  },
  {
    path: 'order-payment-completed',
    loadChildren: () => import('./order/order-payment-completed.module').then(m => m.OrderPaymentCompletedModule)
  },
  {
    path: 'cancelled-pay-here-payment',
    redirectTo: "/order-cancelled",
  },
  {
    path: 'order-cancelled',
    loadChildren: () => import('./order/order-payment-completed.module').then(m => m.OrderPaymentCompletedModule)
  },
  {
    path: 'corporate',
    loadChildren: () => import('./corporate/corporate.module').then(m => m.CorporateModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'data-policy',
    loadChildren: () => import('./data-policy/data-policy.module').then(m => m.DataPolicyModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'track/:orderNumber',
    loadChildren: () => import('./track/track.module').then(m => m.TrackModule)
  },
  {
    path: "**",
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "top"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
