import { Injectable } from '@angular/core';
import {Event, RouterEvent, Router, NavigationEnd} from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterEventService {

  routes: string[] = [];
  constructor(public router: Router) {
    router.events.pipe(
       filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)
    ).subscribe((e: RouterEvent) => {
      if(e instanceof NavigationEnd && ((this.routes.includes(e.url) && this.routes[this.routes.length - 1] != e.url) || !this.routes.includes(e.url))) {
        this.routes.push((e.url));
      }
    });
  }

}
