import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../Models/category.model';
import { GiftItemStock } from '../Models/gift-item-stock.model'
import { GiftItem } from '../Models/gift-item.model';
import { ImageFile } from '../Models/image-file.model';
import { CarouselImage } from '../Models/carousel-image.model';
import { GiftItemType } from '../Models/gift-item-type.model';
import { GiftItemOption } from '../Models/gift-item-option.model';
import { Discount } from '../Models/discount.model';
import { GiftStore } from '../Models/gift-store.model';
import { GiftPackage } from '../Models/gift-package.model';
import { PayHereHashGenInput } from '../Models/pay-here-hash-gen-input';
import { PayHerePaymentInfo } from '../Models/pay-here-payment-info';
import { CategoryGroup } from '../Models/category-group.model';
import { RequestQuotationForm } from '../Models/request-quotation-form.model';
import { EventType } from '../Models/event-type.model';
import { SubmitRequestForm } from '../Models/submit-request-form.model';
import { DeliveryLocation } from '../Models/delivery-location.model';
import { GiftPackageStock } from '../Models/gift-package-stock.model';
import { SiteConfigurator } from '../Models/site-configuration';

@Injectable({
  providedIn: 'root'
})
export class GiftyAppService {

  constructor(private http: HttpClient) { }

  headers = new HttpHeaders({'accept':'text/json'});
  imageBinaryPrefix: string = "data:image/jpeg;base64,";

  //giftItems service

  getGiftItemOData(queriedURI: string): Observable<GiftItem> {
    return this.http.get<GiftItem>(environment.apiBaseUrl + `odata/GiftItemsOData${queriedURI}`);
  }
  getGiftItemList(queriedURI: string): Observable<GiftItem[]> {
    return this.http.get<any>(environment.apiBaseUrl + `odata/GiftItemsOData${queriedURI}`, {headers: this.headers});
  }
  getGiftItems(includeRemoved: boolean = true): Observable<GiftItem[]> {
    return this.http.get<GiftItem[]>(environment.apiBaseUrl + `api/giftitems?includeRemoved=${includeRemoved}`);
  }
  getGiftItem(id: number| string): Observable<GiftItem> {
    return this.http.get<GiftItem>(environment.apiBaseUrl + `api/giftitems/${id}`);
  }
  getGiftItemByCode(code: number| string): Observable<GiftItem> {
    // return this.http.get<GiftItem>(environment.apiBaseUrl + `api/giftitems/${id}`); getShopGiftItem
    return this.http.get<GiftItem>(environment.apiBaseUrl + `api/giftitems/code/${code}`); 
  }
  addGiftItem(giftItem: GiftItem): Observable<GiftItem> {
    return this.http.post<GiftItem>(environment.apiBaseUrl + 'api/giftitems', giftItem);
  }
  updateGiftItem(id: number| string, data: GiftItem): Observable<GiftItem> {
    return this.http.put<GiftItem>(environment.apiBaseUrl + `api/giftitems/${id}`, data);
  }
  updateGiftItemSeasonalGift(id: number| string, seasonalGift: boolean): Observable<GiftItem> {
    return this.http.put<GiftItem>(environment.apiBaseUrl + `api/giftitems/${id}/SeasonalGift`, seasonalGift);
  }
  deleteGiftItem(id: number| string): Observable<GiftItem> {
    return this.http.delete<GiftItem>(environment.apiBaseUrl + `api/giftitems/${id}`);
  }

  //category service

  getCategoryListOdata(queriedURI: string):Observable<Category[]> {
    return this.http.get<any>(environment.apiBaseUrl + `odata/CategoriesOData${queriedURI}`, {headers: this.headers});
  }
  getCategoryList(): Observable<Category[]> {
    return this.http.get<Category[]>(environment.apiBaseUrl + 'api/categories');
  }
  getCategory(id: number| string): Observable<Category> {
    return this.http.get<Category>(environment.apiBaseUrl + `api/categories/${id}`);
  }
  addCategory(category: Category): Observable<Category> {
    return this.http.post<Category>(environment.apiBaseUrl + 'api/categories', category);
  }
  updateCategory(id: number| string, data: Category): Observable<Category> {
    return this.http.put<Category>(environment.apiBaseUrl + `api/categories/${id}`, data);
  }
  deleteCategory(id: number| string): Observable<Category> {
    return this.http.delete<Category>(environment.apiBaseUrl + `api/categories/${id}`);
  }

  //categoryGroup service

  getCategoryGroupListOdata(queriedURI: string):Observable<any> {
    return this.http.get<any>(environment.apiBaseUrl + `odata/CategoryGroupsOData${queriedURI}`);
  }
  getCategoryGroupList(): Observable<CategoryGroup[]> {
    return this.http.get<CategoryGroup[]>(environment.apiBaseUrl + 'api/categoryGroups');
  }
  getCategoryGroup(id: number| string): Observable<CategoryGroup> {
    return this.http.get<CategoryGroup>(environment.apiBaseUrl + `api/categoryGroups/${id}`);
  }
  addCategoryGroup(category: Category): Observable<CategoryGroup> {
    return this.http.post<CategoryGroup>(environment.apiBaseUrl + 'api/categoryGroups', category);
  }
  updateCategoryGroup(id: number| string, data: Category): Observable<CategoryGroup> {
    return this.http.put<CategoryGroup>(environment.apiBaseUrl + `api/categoryGroups/${id}`, data);
  }
  deleteCategoryGroup(id: number| string): Observable<CategoryGroup> {
    return this.http.delete<CategoryGroup>(environment.apiBaseUrl + `api/categoryGroups/${id}`);
  }

  //discount service

  getDiscountList(): Observable<Discount[]> {
    return this.http.get<Discount[]>(environment.apiBaseUrl + 'api/discounts');
  }
  getDiscount(id: number| string): Observable<Discount> {
    return this.http.get<Discount>(environment.apiBaseUrl + `api/discounts/${id}`);
  }
  addDiscount(discount: Discount): Observable<Discount> {
    return this.http.post<Discount>(environment.apiBaseUrl + 'api/discounts', discount);
  }
  updateDiscount(id: number| string, data: Discount): Observable<Discount> {
    return this.http.put<Discount>(environment.apiBaseUrl + `api/discounts/${id}`, data);
  }
  deleteDiscount(id: number| string): Observable<Discount> {
    return this.http.delete<Discount>(environment.apiBaseUrl + `api/discounts/${id}`);
  }

  //giftItemStocks service

  getGiftItemStockList(): Observable<GiftItemStock[]> {
    return this.http.get<GiftItemStock[]>(environment.apiBaseUrl + 'api/giftitemstocks');
  }
  getGiftItemStock(id: number| string): Observable<GiftItemStock> {
    return this.http.get<GiftItemStock>(environment.apiBaseUrl + `api/giftitemstocks/${id}`);
  }
  addGiftItemStock(giftItemStock: GiftItemStock): Observable<GiftItemStock> {
    return this.http.post<GiftItemStock>(environment.apiBaseUrl + 'api/giftitemstocks', giftItemStock);
  }
  updateGiftItemStock(id: number| string, data: GiftItemStock): Observable<GiftItemStock> {
    return this.http.put<GiftItemStock>(environment.apiBaseUrl + `api/giftitemstocks/${id}`, data);
  }
  deleteGiftItemStock(id: number| string): Observable<GiftItemStock> {
    return this.http.delete<GiftItemStock>(environment.apiBaseUrl + `api/giftitemstocks/${id}`);
  }

  //giftPackageStocks service

  getGiftPackageStockList(): Observable<GiftPackageStock[]> {
    return this.http.get<GiftPackageStock[]>(environment.apiBaseUrl + 'api/giftPackageStocks');
  }
  getGiftPackageStock(id: number| string): Observable<GiftPackageStock> {
    return this.http.get<GiftPackageStock>(environment.apiBaseUrl + `api/giftPackageStocks/${id}`);
  }
  addGiftPackageStock(giftPackageStock: GiftPackageStock): Observable<GiftPackageStock> {
    return this.http.post<GiftPackageStock>(environment.apiBaseUrl + 'api/giftPackageStocks', giftPackageStock);
  }
  updateGiftPackageStock(id: number| string, data: GiftPackageStock): Observable<GiftPackageStock> {
    return this.http.put<GiftPackageStock>(environment.apiBaseUrl + `api/giftPackageStocks/${id}`, data);
  }
  deleteGiftPackageStock(id: number| string): Observable<GiftPackageStock> {
    return this.http.delete<GiftPackageStock>(environment.apiBaseUrl + `api/giftPackageStocks/${id}`);
  }

  //giftPackage Service

  getGiftPackageOData(queriedURI: string): Observable<GiftPackage> {
    return this.http.get<GiftPackage>(environment.apiBaseUrl + `odata/GiftPackagesOData${queriedURI}`);
  }
  getGiftPackageList(queriedURI: string): Observable<any> {
    return this.http.get<any>(environment.apiBaseUrl + `odata/GiftPackagesOData${queriedURI}`, {headers: this.headers});
  }
  getGiftPackages(includeRemoved: boolean = true): Observable<GiftPackage[]> {
    return this.http.get<GiftPackage[]>(environment.apiBaseUrl + `api/giftPackage?includeRemoved=${includeRemoved}`);
  }
  getGiftPackage(id: number|string): Observable<GiftPackage> {
    return this.http.get<GiftPackage>(environment.apiBaseUrl + `api/giftPackage/${id}`);
  }
  getGiftPackageByCode(code: number|string): Observable<GiftPackage> {
    return this.http.get<GiftPackage>(environment.apiBaseUrl + `api/giftPackage/code/${code}`);
  }

  getFullyLoadedGiftPackage(id: number|string): Observable<GiftPackage> {
    return this.http.get<GiftPackage>(environment.apiBaseUrl + `api/giftPackage/${id}/full`);
  }
  addGiftPackage(giftPackage: GiftPackage): Observable<GiftPackage> {
    return this.http.post<GiftPackage>(environment.apiBaseUrl + 'api/giftPackage', giftPackage);
  }
  updateGiftPackage(id: number|string, data: GiftPackage): Observable<GiftPackage> {
    return this.http.put<GiftPackage>(environment.apiBaseUrl + `api/giftPackage/${id}`, data);
  }
  updateGiftPackageSeasonalGift(id: number|string, seasonalGift: boolean):  Observable<GiftPackage>{
    return this.http.put<GiftPackage>(environment.apiBaseUrl + `api/giftPackage/${id}/seasonalGift`, seasonalGift);
  }
  deleteGiftPackage(id: number|string): Observable<GiftPackage> {
    return this.http.delete<GiftPackage>(environment.apiBaseUrl + `api/giftPackage/${id}`);
  }

  //giftItemTypes service

  getGiftItemTypeList(): Observable<GiftItemType[]> {
    return this.http.get<GiftItemType[]>(environment.apiBaseUrl + 'api/giftItemTypes');
  }
  getGiftItemType(id: number| string): Observable<GiftItemType> {
    return this.http.get<GiftItemType>(environment.apiBaseUrl + `api/giftItemTypes/${id}`);
  }
  addGiftItemType(giftItemType: GiftItemType): Observable<GiftItemType> {
    return this.http.post<GiftItemType>(environment.apiBaseUrl + 'api/giftItemTypes', giftItemType);
  }
  updateGiftItemType(id: number| string, data: GiftItemType): Observable<GiftItemType> {
    return this.http.put<GiftItemType>(environment.apiBaseUrl + `api/giftItemTypes/${id}`, data);
  }
  deleteGiftItemType(id: number| string): Observable<GiftItemType> {
    return this.http.delete<GiftItemType>(environment.apiBaseUrl + `api/giftItemTypes/${id}`);
  }

  //GiftItemOptions service
  getItemOptions(): Observable<GiftItemOption[]> {
    return this.http.get<GiftItemOption[]>(environment.apiBaseUrl + 'api/giftItemOptions');
  }
  getItemOption(id: number| string): Observable<GiftItemOption> {
    return this.http.get<GiftItemOption>(environment.apiBaseUrl + `api/giftItemOptions/${id}`);
  }
  addItemOption(option: GiftItemOption): Observable<GiftItemOption> {
    return this.http.post<GiftItemOption>(environment.apiBaseUrl + 'api/giftItemOptions', option);
  }
  updateItemOption(id: number| string, data: GiftItemOption): Observable<GiftItemOption> {
    return this.http.put<GiftItemOption>(environment.apiBaseUrl + `api/giftItemOptions/${id}`, data);
  }
  deleteItemOption(id: number| string): Observable<GiftItemOption> {
    return this.http.delete<GiftItemOption>(environment.apiBaseUrl + `api/giftItemOptions/${id}`);
  }

  //giftStores service

  getGiftStoreList(): Observable<GiftStore[]> {
    return this.http.get<GiftStore[]>(environment.apiBaseUrl + 'api/giftstores');
  }

  getGiftStore(id: number| string): Observable<GiftStore> {
    return this.http.get<GiftStore>(environment.apiBaseUrl + `api/giftstores/${id}`);
  }
  addGiftStore(giftStore: GiftStore): Observable<GiftStore> {
    return this.http.post<GiftStore>(environment.apiBaseUrl + 'api/giftstores', giftStore);
  }
  updateGiftStore(id: number| string, data: GiftStore): Observable<GiftStore> {
    return this.http.put<GiftStore>(environment.apiBaseUrl + `api/giftstores/${id}`, data);
  }
  deleteGiftStore(id: number| string): Observable<GiftStore> {
    return this.http.delete<GiftStore>(environment.apiBaseUrl + `api/giftstores/${id}`);
  }

  //imageFiles service

  getImageFileList(): Observable<ImageFile[]> {
    return this.http.get<ImageFile[]>(environment.apiBaseUrl + 'api/imagefiles');
  }
  getImageFile(id: number| string): Observable<ImageFile> {
    return this.http.get<ImageFile>(environment.apiBaseUrl + `api/imagefiles/${id}`);
  }
  getImageFiles(id: string): Observable<ImageFile[]> {
    const search = ',';
    const replaceWith = '%2C';
    const result = id.split(search).join(replaceWith);
    return this.http.get<ImageFile[]>(environment.apiBaseUrl + `api/ImageFiles/list?ids=${result}`);
  }
  addImageFile(imageFile: ImageFile): Observable<ImageFile> {
    return this.http.post<ImageFile>(environment.apiBaseUrl + 'api/imagefiles', imageFile);
  }
  updateImageFile(id: number| string, data: ImageFile): Observable<ImageFile> {
    return this.http.put<ImageFile>(environment.apiBaseUrl + `api/imagefiles/${id}`, data);
  }
  deleteImageFile(id: number| string): Observable<ImageFile> {
    return this.http.delete<ImageFile>(environment.apiBaseUrl + `api/imagefiles/${id}`);
  }

  //RequestQuotationForm Service

  getRequestQuotationFormList(): Observable<RequestQuotationForm[]> {
    return this.http.get<RequestQuotationForm[]>(environment.apiBaseUrl + 'api/requestQuotationForms');
  }

  addRequestQuotationForm(reqQuotationForm: RequestQuotationForm): Observable<RequestQuotationForm> {
    return this.http.post<RequestQuotationForm>(environment.apiBaseUrl + 'api/requestQuotationForms', reqQuotationForm);
  }

  updateRequestQuotationForm(id: number | string, form: RequestQuotationForm): Observable<RequestQuotationForm> {
    return this.http.put<RequestQuotationForm>(environment.apiBaseUrl + `api/requestQuotationForms/${id}`, form);
  }

  //SubmitRequestForm Service

  getCustomerRequestFormList(): Observable<SubmitRequestForm[]> {
    return this.http.get<SubmitRequestForm[]>(environment.apiBaseUrl + 'api/SubmitRequestForms');
  }

  addCustomerRequestForm(form: SubmitRequestForm): Observable<SubmitRequestForm> {
    return this.http.post<SubmitRequestForm>(environment.apiBaseUrl + 'api/SubmitRequestForms', form);
  }

  updateCustomerRequestForm(id: number | string, form: SubmitRequestForm): Observable<SubmitRequestForm> {
    return this.http.put<SubmitRequestForm>(environment.apiBaseUrl + `api/SubmitRequestForms/${id}`, form);
  }

  //EventType Service

  getEventTypeList(): Observable<EventType[]> {
    return this.http.get<EventType[]>(environment.apiBaseUrl + 'api/eventTypes');
  }
  getEventType(id: number| string): Observable<EventType> {
    return this.http.get<EventType>(environment.apiBaseUrl + `api/eventTypes/${id}`);
  }
  addEventType(type: EventType): Observable<EventType> {
    return this.http.post<EventType>(environment.apiBaseUrl + 'api/eventTypes', type);
  }
  updateEventType(id: number| string, data: EventType): Observable<EventType> {
    return this.http.put<EventType>(environment.apiBaseUrl + `api/eventTypes/${id}`, data);
  }
  deleteEventType(id: number| string): Observable<EventType> {
    return this.http.delete<EventType>(environment.apiBaseUrl + `api/eventTypes/${id}`);
  }

  //DeliveryLocations Service

  getDeliveryLocationList(): Observable<DeliveryLocation[]> {
    return this.http.get<DeliveryLocation[]>(environment.apiBaseUrl + 'api/deliveryLocations');
  }
  getDeliveryLocation(id: number| string): Observable<DeliveryLocation> {
    return this.http.get<DeliveryLocation>(environment.apiBaseUrl + `api/deliveryLocations/${id}`);
  }
  addDeliveryLocation(location: DeliveryLocation): Observable<DeliveryLocation> {
    return this.http.post<DeliveryLocation>(environment.apiBaseUrl + 'api/deliveryLocations', location);
  }
  updateDeliveryLocation(id: number| string, data: DeliveryLocation): Observable<DeliveryLocation> {
    return this.http.put<DeliveryLocation>(environment.apiBaseUrl + `api/deliveryLocations/${id}`, data);
  }
  deleteDeliveryLocation(id: number| string): Observable<DeliveryLocation> {
    return this.http.delete<DeliveryLocation>(environment.apiBaseUrl + `api/deliveryLocations/${id}`);
  }

  //CarouselImages service

  getCarouselImageList(): Observable<CarouselImage[]> {
    return this.http.get<CarouselImage[]>(environment.apiBaseUrl + 'api/carouselimages');
  }
  getCarouselImage(id: number| string): Observable<CarouselImage> {
    return this.http.get<CarouselImage>(environment.apiBaseUrl + `api/carouselimages/${id}`);
  }
  // getImageContent(id: number| string): Observable<CarouselImage> {
  //   return this.http.get<CarouselImage>(environment.apiBaseUrl + `api/carouselimages/image/${id}`);
  // }
  getImagesByFlag(withBinaryData: boolean): Observable<CarouselImage[]> {
    return this.http.get<CarouselImage[]>(environment.apiBaseUrl + `api/CarouselImages?withBinaryData=${withBinaryData}`);
  }
  addCarouselImage(imageFile: CarouselImage): Observable<CarouselImage> {
    return this.http.post<CarouselImage>(environment.apiBaseUrl + 'api/carouselimages', imageFile);
  }
  updateCarouselImage(id: number| string, data: ImageFile): Observable<CarouselImage> {
    return this.http.put<CarouselImage>(environment.apiBaseUrl + `api/carouselimages/${id}`, data);
  }
  deleteCarouselImage(id: number| string): Observable<CarouselImage> {
    return this.http.delete<CarouselImage>(environment.apiBaseUrl + `api/carouselimages/${id}`);
  }

  getPayHerePaymentByOrderId(orderId : string) : Observable<PayHerePaymentInfo> {
    return this.http.get<PayHerePaymentInfo>(environment.apiBaseUrl + `api/payhere/payment/order/${orderId}`);
  }

  //Site-Configurations
  getSiteConfigurator(configuration: string) {
    return this.http.get<SiteConfigurator>(environment.apiBaseUrl + `api/SiteConfig/${configuration}`);
  }

  updateSiteConfigurator(setting: string, config: SiteConfigurator) {
    return this.http.put<SiteConfigurator>(environment.apiBaseUrl + `api/SiteConfig/${setting}`, config);
  }

}
