import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, shareReplay} from 'rxjs';
import { switchMap } from "rxjs/operators";
import { AppAuthService } from './app-auth.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

  constructor(private appAuthService: AppAuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(!this.appAuthService.shouldInjectAuthorization(request)){
      request = request.clone({
        setHeaders: { 'X-Tenant-ID': this.appAuthService.tenantId, 'X-Guest-User-ID': this.appAuthService.guestUserId }
      });
      return next.handle(request);
    }

    return this.appAuthService.tryGetAccessToken()
    .pipe(
      shareReplay(),
      switchMap( token => {
        let authorizationAnonymous = request.headers.get('Authorization-Anonymous');
        if (token && (authorizationAnonymous == undefined || authorizationAnonymous !== 'true')) {
          request = request.clone({
            setHeaders: { Authorization: 'Bearer ' + token }
          });
        }
        else {
          request = request.clone({
            setHeaders: { 'X-Tenant-ID': this.appAuthService.tenantId, 'X-Guest-User-ID': this.appAuthService.guestUserId }
          });
        }
        return next.handle(request);
      })
    )
  }
}
