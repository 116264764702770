import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, map, of, take } from 'rxjs';
import { AppUserService } from './app-user.service';
import { AppAuthService } from './app-auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private appUserService: AppUserService) { }

  hasPermission(permission : string): Observable<boolean> {
    return this.appUserService.permissions
    .pipe(
      map( perms => {
        let selected = perms.find(p => p === permission);
        return selected ? true : false;
      })
    )
  }
}

export const AuthGuard: CanActivateFn =
(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  let router =  inject(Router);
  if(!inject(AppAuthService).isLoggedIn()){
    router.navigate(['/login'], { queryParams : {redirectUrl: state.url} } );
    return false;
  }
  return true;
};


export const CanActivateReadBackOffice: CanMatchFn = (route, segments) => {
  let router = inject(Router);
  return inject(PermissionService).hasPermission('ReadBackOffice').pipe(
    take(1),  // Ensures the observable emits once and completes
    map((isPermitted: boolean) => {
      if (!isPermitted) {
        router.navigate(['/']);
        return false;
      }
      return true;
    }),
    catchError(() => {
      router.navigate(['/']);
      return of(false);
    })
  );
};

export const CanActivateProfile: CanActivateFn =
(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  let permission: boolean;
  let router =  inject(Router);
  inject(PermissionService).hasPermission('ManageUserProfile').subscribe((isPermitted) => {
    if(!isPermitted){
      router.navigate(['/']);
    }
    permission = isPermitted;
  });
  return permission!;
};

//https://github.com/grrlgeek/bak-to-bacpac
//https://medium.com/echohub/angular-role-based-routing-access-with-angular-guard-dbecaf6cd685
//https://medium.com/scrum-and-coke/angular-guard-for-role-based-access-control-rbac-based-on-jwt-e79dfdb41f2a
