import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SitemapService {
  constructor(
    @Inject('BASE_API_URL') private apiBaseUrl: string,
    @Inject('BASE_APP_URL') private appBaseUrl: string,
    private http: HttpClient
  ) {}

  getItems(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/seo/items`).pipe(
      catchError((error) => {
        console.error('Failed to fetch items:', error);
        return of([]);
      })
    );
  }

  getPackages(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiBaseUrl}/seo/packages`).pipe(
      catchError((error) => {
        console.error('Failed to fetch packages:', error);
        return of([]);
      })
    );
  }

  generateStaticUrls(): Array<{ loc: string; lastmod: string; priority: number }> {
    const today = new Date().toISOString();
    return [
      { loc: `${this.appBaseUrl}/`, lastmod: today, priority: 1.0 },
      { loc: `${this.appBaseUrl}/store/packages`, lastmod: today, priority: 0.8 },
      { loc: `${this.appBaseUrl}/store/items`, lastmod: today, priority: 0.8 },
      { loc: `${this.appBaseUrl}/about`, lastmod: today, priority: 0.6 },
      { loc: `${this.appBaseUrl}/contactus`, lastmod: today, priority: 0.6 },
    ];
  }

  generateItemsUrls(items: any[]): Array<{ loc: string; lastmod: string; priority: number }> {
    return items.map((item) => ({
      loc: `${this.appBaseUrl}/store/items/${item.code}`,
      lastmod: new Date(item.lastUpdate).toISOString(),
      priority: 0.8,
    }));
  }

  generatePackagesUrls(packages: any[]): Array<{ loc: string; lastmod: string; priority: number }> {
    return packages.map((pkg) => ({
      loc: `${this.appBaseUrl}/store/packages/${pkg.code}`,
      lastmod: new Date(pkg.lastUpdate).toISOString(),
      priority: 0.8,
    }));
  }

  generateSitemapXml(urls: Array<{ loc: string; lastmod: string; priority: number }>): string {
    let sitemap = `<?xml version="1.0" encoding="UTF-8"?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;

    urls.forEach(({ loc, lastmod, priority }) => {
      sitemap += `
        <url>
          <loc>${loc}</loc>
          <lastmod>${lastmod}</lastmod>
          <priority>${priority}</priority>
        </url>`;
    });

    sitemap += `\n</urlset>`;
    return sitemap;
  }

  generateFullSitemap(): Observable<string> {
    return forkJoin([this.getItems(), this.getPackages()]).pipe(
      map(([items, packages]) => {
        const urls = [
          ...this.generateStaticUrls(),
          ...this.generateItemsUrls(items),
          ...this.generatePackagesUrls(packages),
        ];
        return this.generateSitemapXml(urls);
      })
    );
  }
}
