import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, catchError, map, of, shareReplay, tap, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import * as moment from "moment";
import { Tokens } from '../Models/tokens';
import { UserPasswordLogin } from '../Models/user-password-login';
import { UserSignUpModel } from '../Models/user-sign-up-model';
import { v4 as uuidv4 } from 'uuid';
import {StorageService} from "./storage.service";
import { SignalRService } from './signal-r.service';

@Injectable({
  providedIn: 'root'
})
export class AppAuthService {

  accessTokenKey = 'access_token';
  accessTokenExpiresAtKey = 'access_token_expires_at';
  refreshTokenKey = 'refresh_token';
  refreshTokenExpiresAtKey = 'refresh_token_expires_at';
  guestUserIdKey = 'guest_user_id';
  authExclusionsUrls = ['auth/login', 'auth/login-google', 'auth/register', 'auth/refresh-token'];

  constructor(private http: HttpClient, private storageService: StorageService, private signalRService: SignalRService) { }

  private _signInSignOutEvents$: Subject<Date> = new Subject<Date>();
  private _authenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get tenantId(): string {
    return environment.tenantId;
  }

  get guestUserId(): string {
    const guestUserId = this.storageService.getItem(this.guestUserIdKey);
    if (!guestUserId) {
      const guestUserId = uuidv4();
      this.storageService.setItem(this.guestUserIdKey, guestUserId);
      return guestUserId;
    }

    return guestUserId;
  }

  public shouldInjectAuthorization(request: HttpRequest<unknown>): boolean {
    let founds = this.authExclusionsUrls.filter(url =>  request.url.endsWith(url));
    return founds.length == 0;
  }

  public loginGoogle(idToken: string): Observable<Tokens> {
    let formData: any = new FormData();
    formData.append("idToken", idToken);
    return this.http.post<Tokens>(environment.apiBaseUrl + `auth/login-google`, formData)
    .pipe(
      tap(tokens => {
        this.setSession(tokens);
        this.setAuthenticated(true);
        console.debug('Is user logged-in ', this.isLoggedIn());
      })
    );
  }

  public loginFacebook(accessToken: string): Observable<Tokens> {
    let formData: any = new FormData();
    formData.append("accessToken", accessToken);
    return this.http.post<Tokens>(environment.apiBaseUrl + `auth/login-facebook`, formData)
    .pipe(
      tap(tokens => {
        this.setSession(tokens);
        this.setAuthenticated(true);
        console.debug('Is user logged-in ', this.isLoggedIn());
      })
    );
  }

  public loginWithPasswordCredentials(userPasswordLogin: UserPasswordLogin): Observable<Tokens> {
    userPasswordLogin.email = userPasswordLogin.username;
    return this.http.post<Tokens>(environment.apiBaseUrl + `auth/login`, userPasswordLogin)
    .pipe(
      tap(tokens => {
        this.setSession(tokens);
        this.setAuthenticated(true);
        console.debug('Is user logged-in ', this.isLoggedIn());
      })
    );
  }

  public signup(userSignUpModel: UserSignUpModel): Observable<any> {
    return this.http.post<any>(environment.apiBaseUrl + `auth/register`, userSignUpModel);
  }

  public initializeLoggedIn() : Observable<boolean> {
    console.debug('try initialize with existing logged-in.');
    let loggedIn = this.isLoggedIn();
    if(loggedIn && this.GetAccessToken()) {
      this.setAuthenticated(true);
      console.debug('user is logged-in with existing credentials.');
    }
    else{
      this.logoutInternal();
      console.debug('User is Not logged-in with existing credentials.');
    }
    return of(loggedIn);
  }

  private GetAccessToken() : string | undefined
  {
    let token = this.storageService.getItem(this.accessTokenKey);
    if(token) {
      return token;
    }
    return undefined;
  }

  private GetRefreshToken() : string | undefined
  {
    let token = this.storageService.getItem(this.refreshTokenKey);
    if(token) {
      return token;
    }
    return undefined;
  }

  public tryGetAccessToken() : Observable<string | undefined>
  {
    if(!this.isLoggedIn()){
      return of(undefined);
    }

    if(!this.isAccessTokenExpired()) {
      console.debug('Access token is valid');
      return of(this.GetAccessToken());
    }
    console.debug('Access token is expired.');
    console.debug('Try refreshing the token using refresh.');

    let formData: any = new FormData();
    formData.append("refreshToken", this.GetRefreshToken());
    return this.http.post<Tokens>(environment.apiBaseUrl + `auth/refresh-token`, formData)
    .pipe(
      catchError(err => {
        this.setAuthenticated(false);
        this.logoutInternal();
        return of(undefined)
      }),
      tap(tokens => {
        if(tokens) {
          console.debug('Refreshed tokens ', tokens);
          this.setAccessToken(tokens);
        }
      }),
      map(token => token?.accessToken),
      shareReplay()
    );
  }

  get authenticated(): Observable<boolean> {
    return this._authenticated$.asObservable();
  }

  setAuthenticated(value: boolean) {
    this._authenticated$.next(value);
    this.fireSignInSignOutEvents();
  }

  private setAccessToken(authResult: Tokens) {
    const accessTokenExpiresAt = moment().add(authResult.accessTokenExpiresIn, 'second');

    this.storageService.setItem(this.accessTokenKey, authResult.accessToken);
    this.storageService.setItem(this.accessTokenExpiresAtKey, JSON.stringify(accessTokenExpiresAt.valueOf()));
  }


  private setSession(authResult: Tokens) {
    const accessTokenExpiresAt = moment().add(authResult.accessTokenExpiresIn, 'second');
    const refreshTokenExpiresAt = moment().add(authResult.refreshTokenExpiresIn, 'second');

    this.storageService.setItem(this.accessTokenKey, authResult.accessToken);
    this.storageService.setItem(this.refreshTokenKey, authResult.refreshToken);
    this.storageService.setItem(this.accessTokenExpiresAtKey, JSON.stringify(accessTokenExpiresAt.valueOf()));
    this.storageService.setItem(this.refreshTokenExpiresAtKey, JSON.stringify(refreshTokenExpiresAt.valueOf()));
  }

  private logoutInternal() {
    this.storageService.removeItem(this.accessTokenKey);
    this.storageService.removeItem(this.accessTokenExpiresAtKey);
    this.storageService.removeItem(this.refreshTokenKey);
    this.storageService.removeItem(this.refreshTokenExpiresAtKey);
  }

  public signOut(): void {
    this.setAuthenticated(false);
    this.logoutInternal();
    this.signalRService.stopConnection();
  }

  public isAccessTokenExpired() {
    const exp = this.getTokenExpiration(this.accessTokenExpiresAtKey);
    return !moment().isBefore(exp);
  }

  public isLoggedIn() {
    const exp = this.getTokenExpiration(this.refreshTokenExpiresAtKey);
    if(exp){
      return moment().isBefore(exp);
    }
    return false;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getTokenExpiration(key : string) : moment.Moment | undefined {
    const expiration = this.storageService.getItem(key);
    if (expiration) {
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
    }

    return undefined;
  }

  get signInSignOutEvents(): Observable<Date> {
    return this._signInSignOutEvents$.asObservable();
  }

  fireSignInSignOutEvents() {
    this._signInSignOutEvents$.next(new Date());
  }

}
