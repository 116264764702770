import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, map, switchMap, tap} from "rxjs";
import {UserInfoModel} from "../Models/user-info-model";
import {environment} from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ResetPasswordResponse } from '../Models/reset-password-response';
import { SignalRService } from './signal-r.service';
import { NotificationGroup } from '../Models/signal-r.enum';

@Injectable({
  providedIn: 'root'
})
export class AppUserService {

  private _picture$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private _currentUser$: BehaviorSubject<UserInfoModel | undefined> = new BehaviorSubject<UserInfoModel | undefined>(undefined);
  private _permissions$: BehaviorSubject<string[] | undefined> = new BehaviorSubject<string[]  | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private signalRService: SignalRService) {
  }

  loadCurrentUser(): Observable<any> {
    return this.http.get<UserInfoModel>(environment.apiBaseUrl + `api/User/current`)
    .pipe(
      tap(user => this.setCurrentUserPicture(user)),
      tap(user => this.setPicture(user.picture)),
      switchMap(user => this.loadPermissions())
    );
  }

  connectToNotificationHub(){
    this.currentUser.subscribe(
      {
        next: user => {
          if(user && user.defaultUserRole?.roleId === NotificationGroup.Administrator.toLocaleLowerCase()){
            if(this.signalRService.isConnected()){
              this.signalRService.joinGroupFeed(`${environment.tenantId}-${user.defaultUserRole.roleId}`)
              .catch(error =>{
                console.error(error);
              });;
            } else {
              this.signalRService.startConnection().then(() => {
                this.signalRService.joinGroupFeed(`${environment.tenantId}-${user.defaultUserRole.roleId}`);
              })
              .catch(error =>{
                console.error(error);
              });
            }
          }
        },
        error: err => {},
        complete: () => {
          this.signalRService.listenToNotifications(message => {});
        }
      }
    );
  }

  loadUser(userId: string): Observable<UserInfoModel> {
    return this.http.get<UserInfoModel>(environment.apiBaseUrl + `api/User/user-by-id/${userId}`);
  }

  loadUsers(): Observable<UserInfoModel[]> {
    return this.http.get<UserInfoModel[]>(environment.apiBaseUrl + `api/applicationUser`);
  }

  getUserByEmail(email: string): Observable<any> {
    return this.http.get<any>(environment.apiBaseUrl + `api/applicationUser/${email}`);
  }

  loadPermissions(): Observable<string[]> {
    return this.http.get<string[]>(environment.apiBaseUrl + `api/User/permissions`)
    .pipe(tap(perms => this.setPermissions(perms)));
  }

  sendPasswordResetOTP(email: string): Observable<any> {
    let formData: any = new FormData();
    formData.append("email", email);
    return this.http.post<any>(environment.apiBaseUrl + `api/User/sendPasswordResetOTP`, formData);
  }

  verifyPasswordResetOTP(email: string, otp: string): Observable<string> {
    let formData: any = new FormData();
    formData.append("email", email);
    formData.append("otp", otp);
    return this.http.post(environment.apiBaseUrl + `api/User/verifyPasswordResetOTP`, formData, {
      responseType: 'text'
    });
  }

  resetPassword(email: string, token: string, newPassword: string): Observable<ResetPasswordResponse> {
    let formData: any = new FormData();
    formData.append("email", email);
    formData.append("token", token);
    formData.append("newPassword", newPassword);
    return this.http.post<ResetPasswordResponse>(environment.apiBaseUrl + `api/User/resetPassword`, formData);
  }

  changePassword(email: string, oldPassword: string, newPasswordFirstInstance: string, newPasswordSecondInstance: string): Observable<string> {
    let formData: any = new FormData();
    formData.append("email", email);
    formData.append("currentPassword", oldPassword);
    formData.append("newPasswordFirstInstance", newPasswordFirstInstance);
    formData.append("newPasswordSecondInstance", newPasswordSecondInstance);
    return this.http.post<string>(environment.apiBaseUrl + `api/User/changePassword`, formData);
  }

  updateUserDetails(userInfo: UserInfoModel) {
    return this.http.post<any>(environment.apiBaseUrl + `api/User/changeUserDetails`, userInfo);
  }

  get picture(): Observable<string | undefined> {
    return this._picture$.asObservable();
  }

  setPicture(value: string) {
    this._picture$.next(value);
  }

  setPermissions(value: string[]) {
    this._permissions$.next(value);
  }

  clearCurrentUser(){
    this._currentUser$.next(undefined);
  }

  assignRolesToUser(userRole: any): Observable<any> {
    return this.http.post<any>(environment.apiBaseUrl + `api/ApplicationUserRole`, userRole);
  }

  get currentUser(): Observable<UserInfoModel> {
    return this._currentUser$.asObservable().pipe(
      map(
        user => user ? user : {} as UserInfoModel
      )
    );
  }

  get permissions(): Observable<string[]> {
    if(!this._permissions$.value){
      return this.loadPermissions();
    }
    return this._permissions$.asObservable().pipe(
      map(v => v as string[])
     );
  }

  get currentUserFullname(): Observable<string> {
    return this._currentUser$.asObservable().pipe(
      map(
        user => user ? user.fullName : ''
      )
    );
  }

  setCurrentUserPicture(value: UserInfoModel) {
    this._currentUser$.next(value);
  }

  
}
