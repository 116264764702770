import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppAuthService } from '../app-auth.service';
import { AppUserService } from '../app-user.service';
import { ShoppingCartService } from '../../Services/shopping-cart.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { SearchSuggestion } from 'src/app/Models/search.model';
import { ProductDetailQuickViewService } from 'src/app/Services/product-detail-quick-view-service/product-detail-quick-view.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProductDetailQuickViewComponent } from '../product-detail-quick-view/product-detail-quick-view.component';
import { GiftItem } from 'src/app/Models/gift-item.model';
import { GiftyAppService } from 'src/app/Services/gifty-app.service';
import { Discount } from 'src/app/Models/discount.model';
import { GiftPackage } from 'src/app/Models/gift-package.model';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { SearchHelperService } from 'src/app/Services/search-helper.service';
import {SpinnerService} from "@xyg/spinner";
import { TrackOrderComponent } from 'src/app/home/components/track-order/track-order.component';

@Component({
  selector: 'xeyn-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)
  @ViewChild('ngSearch') autocomplete!: AutocompleteComponent;
  inputElement!: HTMLInputElement | null;
  keyword: string = 'searchKey'; // Field from SearchSuggestion interface where autocomplete use to search
  variation: string = 'thumbnail'; // Image variation
  historyHeading: string = 'Recently selected items';
  historyIdentifier: string = 'wrapme_search_history';
  minQueryLength: number = 3;
  historyListMaxNumber: number = 5;
  currentItemCount: number = 0;
  overlaySearchBar: boolean = false;
  searchSuggestions!: SearchSuggestion[];
  discounts!: Discount[];
  selected!: Discount | GiftItem | GiftPackage;
  isLoading: boolean = false;
  logoDark = `${environment.cdnUrl}brandings/WrapMe-logo-dark-nav.webp`;
  logoLight = `${environment.cdnUrl}brandings/WrapMe-logo-light-nav.webp`;
  supportPhone: string;
  supportPhoneFormatted: string;

  constructor(
    private router: Router,
    public appAuthService: AppAuthService,
    public appUserService: AppUserService,
    public shoppingCartService: ShoppingCartService,
    private searchHelperService: SearchHelperService,
    private productDetailService: ProductDetailQuickViewService,
    private modalService: NgbModal,
    private giftyService: GiftyAppService,
    public spinnerService: SpinnerService
  ) {
    this.supportPhone = environment.supportPhone;
    this.supportPhoneFormatted = this.convertPhoneNumber(environment.supportPhone);
  }

  ngOnInit(): void {
    this.shoppingCartService.currentItemCount().subscribe({
      next: (value) => (this.currentItemCount = value),
    });

    this.shoppingCartService.cartItemCountChange().subscribe({
      next: (value) => (this.currentItemCount = value),
    });

    this.searchSubject
      .pipe(debounceTime(this.debounceTimeMs), distinctUntilChanged())
      .subscribe((searchValue) => {
        this.performSearch(searchValue);
      });
  }

  openOrderStatusDialog(){
    this.modalService.open(TrackOrderComponent, {centered: true, size: 'xl'});
  }

  ngOnDestroy() {
    this.searchSubject.complete();
  }

  convertPhoneNumber(phoneNumber: string) {
    // Remove spaces and the '+' symbol
    return phoneNumber.replace(/\+| /g, '');
  }

  performSearch(searchValue: string) {
    this.searchSuggestions = [];
    if (searchValue) {
      this.isLoading = true;
      // Perform the search operation
      this.searchHelperService.searchSuggestions(searchValue).subscribe({
        next: (results) => {
          this.searchSuggestions = results.map((suggestion) => {
            suggestion.relevanceInfo.keyword
              ? (suggestion.searchKey = `${suggestion.relevanceInfo.keyword} ${this.autocomplete.query}`)
              : (suggestion.searchKey = `${suggestion.itemName} ${this.autocomplete.query}`);
            return suggestion;
          });
        },
        error: (err) => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          // If search term contains more than one word, do tokenized search
          if (this.isMoreThanOneWord(searchValue)) {
            this.isLoading = true;
            this.performInDepthSearch(searchValue);
          }
        },
      });
    }
  }

  performInDepthSearch(searchValue: string) {
    this.searchHelperService.searchSuggestionsByTokens(searchValue).subscribe({
      next: (results) => {
        this.isLoading = false;
        let newSuggestions = results.map((suggestion) => {
          suggestion.relevanceInfo.keyword
            ? (suggestion.searchKey = `${suggestion.relevanceInfo.keyword} ${this.autocomplete.query}`)
            : (suggestion.searchKey = `${suggestion.itemName} ${this.autocomplete.query}`);
          return suggestion;
        });

        const uniqueNewSuggestions = this.removeDuplicates(newSuggestions);
        this.searchSuggestions =
          this.searchSuggestions.concat(uniqueNewSuggestions);
      },
      error: (err) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  //#region Helpers
  removeDuplicates(newSuggestions: SearchSuggestion[]): SearchSuggestion[] {
    return newSuggestions.filter(
      (newItem) =>
        !this.searchSuggestions.some(
          (existingItem) =>
            existingItem.itemId === newItem.itemId &&
            existingItem.itemType === newItem.itemType
        )
    );
  }

  isMoreThanOneWord(input: string): boolean {
    const words: string[] = input.split(' ');
    return words.length > 1;
  }

  selectEvent(item: SearchSuggestion) {
    if (this.autocomplete) {
      const autoCompleteEl = this.autocomplete.elementRef
        .nativeElement as HTMLElement;
      this.inputElement = autoCompleteEl.querySelector('input');
      this.autocomplete.query = this.inputElement?.value ?? '';
    }

    switch (item.itemType) {
      case 'GiftPackage':
        this.quickViewProductPackage(item.itemId);
        break;
      case 'GiftItem':
        this.quickViewProductItem(item.itemId);
        break;
      case 'CategoryGroupType':
        this.navigateToSelectedCategory(item);
        break;
      default:
        throw new Error('Invalid item type');
    }
  }

  quickViewProductItem(id: number) {
    this.spinnerService.requestStarted();
    const queryURI: string = `(${id})?$expand=giftItemStock,giftItemOptions,cloudFiles($expand=cloudFile($expand=cloudProvider))&$format=application/json;odata.metadata=none`;
    this.giftyService.getGiftItemOData(queryURI).subscribe({
      next: (productItem) => {
        this.productDetailService.productItem = productItem;
        this.productDetailService.isItem = true;
        this.productDetailService.isCustomizedGift = true;
        this.productDetailService.isOutStock =
          productItem.giftItemStock.currentStockSize <= 0 ||
          productItem.giftItemStock.status.toLowerCase() != 'in stock';
        const discount = this.getItemDiscount(true, productItem);
        this.productDetailService.reducedPrice = this.getReducedPrice(
          productItem,
          discount
        );

        const modalRef = this.modalService.open(
          ProductDetailQuickViewComponent,
          {
            centered: true,
            size: 'xl',
          }
        );
        this.openSearchResultsPanel(modalRef);
      },
      error: (err) => {
        this.spinnerService.requestEnded();
      },
      complete: () => {
        this.spinnerService.requestEnded();
      },
    });
  }

  quickViewProductPackage(id: number) {
    this.spinnerService.requestStarted();
    const queryURI: string = `(${id})?$expand=giftPackageStock,giftPackageOptions,cloudFiles($expand=cloudFile($expand=cloudProvider))&$format=application/json;odata.metadata=none`;
    this.giftyService.getGiftPackageOData(queryURI).subscribe({
      next: (productPackage) => {
        console.log('productPackage', productPackage);
        this.productDetailService.productPackage = productPackage;
        this.productDetailService.isItem = false;
        this.productDetailService.isCustomizedGift = false;
        this.productDetailService.isOutStock =
          productPackage.giftPackageStock.currentStockSize <= 0 ||
          productPackage.giftPackageStock.status.toLowerCase() != 'in stock';
        const discount = this.getItemDiscount(false, productPackage);
        this.productDetailService.reducedPrice = this.getReducedPrice(
          productPackage,
          discount
        );

        const modalRef = this.modalService.open(
          ProductDetailQuickViewComponent,
          {
            centered: true,
            size: 'xl',
          }
        );
        this.openSearchResultsPanel(modalRef);
      },
      error: (err) => {
        this.spinnerService.requestEnded();
      },
      complete: () => {
        this.spinnerService.requestEnded();
      },
    });
  }

  private openSearchResultsPanel(modalRef: NgbModalRef) {
    modalRef.result.then(
      (data) => {
        // on close
        if (!this.autocomplete.isOpen) {
          setTimeout(() => {
            this.autocomplete.focus();
            this.autocomplete.open();
          }, 200);
        }
      },
      (reason) => {
        // on dismiss
        if (!this.autocomplete.isOpen) {
          setTimeout(() => {
            this.autocomplete.focus();
            this.autocomplete.open();
          }, 200);
        }
      }
    );
  }

  getItemDiscount(isCustomGift: boolean, item: any): Discount | undefined {
    const itemDiscounts = isCustomGift
      ? (item as GiftItem).giftItemDiscounts
      : (item as GiftPackage).giftPackageDiscounts;
    const discountId = itemDiscounts?.[0]?.discountId;
    return this.discounts?.find(
      (discount) => discount.discountId === discountId
    );
  }

  getReducedPrice(
    searchItem: GiftItem | GiftPackage,
    discount: Discount | undefined
  ): number {
    if (discount?.discountType) {
      switch (discount?.discountType as unknown as string) {
        case 'FixedAmount':
        case 'CategoryAmount':
          return (
            (searchItem.price?.amount ?? 0) -
            (discount?.discountValue?.amount ?? 0)
          );
        case 'Percentage':
        case 'CategoryPercentage':
          return (
            (searchItem.price?.amount ?? 0) -
            ((searchItem.price?.amount ?? 0) *
              (discount?.discountPercentage ?? 0)) /
              100
          );
        default:
          throw new Error('Invalid discount type');
      }
    }
    return 0;
  }

  private navigateToSelectedCategory(item: SearchSuggestion) {
    if (this.router.url.includes('/store')) {
      this.router.navigate(['/store'], {
        queryParams: { page: 'buy-from-store', occasion: item.itemName },
      });
      this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationEnd) {
          window.location.reload();
        }
      });
    } else {
      this.router.navigate(['/store'], {
        queryParams: { page: 'buy-from-store', occasion: item.itemName },
      });
    }
  }

  onChangeSearch(searchValue: string) {
    this.searchSubject.next(searchValue);
  }

  onFocused(event: any) {
    if (!this.discounts) {
      this.giftyService.getDiscountList().subscribe((discounts) => {
        const today: Date = new Date();
        this.discounts = discounts.filter(
          (dis) =>
            dis.discountStatus == 'Active' &&
            today >= new Date(dis.startingDate) &&
            today <= new Date(dis.expiryDate)
        );
      });
    }
  }

  onSearchBar() {
    this.overlaySearchBar = true;
    setTimeout(() => {
      this.autocomplete.focus();
    }, 500);
  }

  onCloseSearch() {
    this.overlaySearchBar = false;
  }

  onImageError(event : any): void {
    event.target.src = this.logoLight;
  }
  //#endregion
}
