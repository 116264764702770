import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {
  AdminOrderUpdate,
  CheckoutOrder,
  ODataOrder,
  Order,
  OrderItemWithFiles,
  OrderWorkflow,
} from '../Models/checkout-order';
import {AddressForm} from '../Models/address-form';
import {OrderCompleteResponse} from "../Models/order-complete-response";
import {OrderComplete} from "../Models/order-complete";
import {CheckoutCartForm} from '../Models/checkout-cart-form';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  headers = new HttpHeaders({ accept: 'text/json' });

  constructor(private http: HttpClient) {}

  loadOrder(orderNumber: string): Observable<CheckoutOrder> {
    return this.http.get<CheckoutOrder>(
      environment.apiBaseUrl + `api/order/${orderNumber}`,
      { headers: this.headers }
    );
  }

  loadOrders(): Observable<Order[]> {
    return this.http.get<Order[]>(environment.apiBaseUrl + `api/order`);
  }

  loadOrdersByUserId(userId: string): Observable<CheckoutOrder[]> {
    return this.http.get<CheckoutOrder[]>(environment.apiBaseUrl + `api/order/user-${userId}`);
  }

  loadOrdersQuery(queriedURI: string):Observable<ODataOrder> {
    return this.http.get<any>(environment.apiBaseUrl + `cart-odata/OrderOData${queriedURI}`);
  }

  loadOrderItems(ids: number[]): Observable<OrderItemWithFiles[]> {
    return this.http.post<OrderItemWithFiles[]>(
      environment.apiBaseUrl + `api/order/get-by-ids`,
      ids
    );
  }

  loadOrderWorkflows(orderNumber: string): Observable<OrderWorkflow[]> {
    return this.http.get<OrderWorkflow[]>(
      environment.apiBaseUrl + `api/order/workflows/${orderNumber}`
    );
  }

  getOrderStatuses(): Observable<string[]> {
    return this.http.get<string[]>(
      environment.apiBaseUrl + `api/order/order-statuses`
    );
  }

  getDeliveryStatuses(): Observable<string[]> {
    return this.http.get<string[]>(
      environment.apiBaseUrl + `api/order/delivery-statuses`
    );
  }

  UpdateOrderStatus(status: AdminOrderUpdate): Observable<Order> {
    return this.http.put<Order>(
      environment.apiBaseUrl + `api/order/update-status`,
      status
    );
  }

  deleteOrder(orderNUmber: string): Observable<boolean> {
    return this.http.delete<boolean>(
      environment.apiBaseUrl + `api/order/delete/${orderNUmber}`
    );
  }

  updateBillingAddress(
    orderId: number,
    billingAddressForm: AddressForm
  ): Observable<boolean> {
    return this.http.post<boolean>(
      environment.apiBaseUrl + `api/order/${orderId}/update-billing-address`,
      billingAddressForm,
      { headers: this.headers }
    );
  }

  applyPromotionCode(orderId: number, cartId : number, promoCode : string): Observable<CheckoutOrder> {
    return this.http.post<CheckoutOrder>(
      environment.apiBaseUrl + `api/order/${orderId}/cart/${cartId}/apply-promotion-code/${encodeURIComponent(promoCode)}`,
      {}
    );
  }

  removePromotionCode(orderId: number): Observable<CheckoutOrder> {
    return this.http.post<CheckoutOrder>(
      environment.apiBaseUrl + `api/order/${orderId}/remove-promotion-code`,
      {}
    );
  }

  completeOrder(orderComplete: OrderComplete) : Observable<OrderCompleteResponse>{
    return this.http.post<OrderCompleteResponse>(
      environment.apiBaseUrl + `api/order/${orderComplete.orderId}/complete`,
      orderComplete,
      { headers: this.headers }
    );
  }

  updateOrderDetails(orderId: number, checkoutCartForm: CheckoutCartForm): Observable<CheckoutOrder> {
    return this.http.put<CheckoutOrder>(environment.apiBaseUrl + `api/order/${orderId}/update-order-details`,
      checkoutCartForm,
      { headers: this.headers });
  }

  abandonOrder(orderId: number): Observable<any> {
    return this.http.post<any>(environment.apiBaseUrl + `api/order/abandon/${orderId}`,
      { headers: this.headers });
  }
}
