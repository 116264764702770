import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'xeyn-social-chat',
  templateUrl: './social-chat.component.html',
  styleUrls: ['./social-chat.component.scss'],
})
export class SocialChatComponent implements OnInit {
  isBackOfficeRoute: boolean = false;
  phone: string = this.convertPhoneNumber(environment.supportPhone);

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(value => {
      if(value instanceof NavigationEnd){
        this.isBackOfficeRoute = value.url.includes('backoffice') || value.url.includes('login');
      }
    });
  }

  convertPhoneNumber(phoneNumber: string) {
    // Remove spaces and the '+' symbol
    return phoneNumber.replace(/\+| /g, '');
  }
}
